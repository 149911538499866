import * as d3 from "d3";
import _ from "lodash";
import { BarAxes } from "./axes/axes";
import { BaseChart } from "./base_chart";
import { Constants } from "./constants";
import { BarControl } from "./controls";
import { BarChartSettings, IBarChartSettings } from "./settings/settings";

// Regular Bubble Chart Has No Controls - Just Plots The Data
export class Bar_Chart extends BaseChart<BarChartSettings> {
  public bars?: any;
  public initialized?: any;

  constructor(raw: any, containerId: string, settings: IBarChartSettings) {
    let activeChartType = "BarChart"; // Default
    let chartType = "BarChart";
    let chartSettings = new BarChartSettings(settings);
    super(raw, containerId, chartSettings, chartType, activeChartType);
  }

  get defaultParams() {
    return Bar_Chart.defaultParams_(this);
  }
  // Loads Data and Sets Base Chart Parameters
  afterInitialize() {
    let self = this;
    super.afterInitialize();

    // Have to Set Time Cursor Before Rendering Axes and Timeline
    self.timeCursor = self.startDate;
    self.axes = BarAxes.bind(self.chart!.append("g"))(self);

    // Timeline Has to Come After Axes
    self.timeline.draw();
    self.timeline.render();

    self.currentData = self.points({ date: self.timeCursor }); // Returns As List of Data Points - Points Do Not Include Hidden Points
    _.sortBy(self.currentData, "Name");

    let points = self.points(); // Cannot Render Axes with Date Filtered Points - Will Cause Issues In Axis Ranges
    self.axes.initialize(points);

    // Must Wait Until We Have Data
    if (self.settings.fundlist.enabled) {
      self.fundList.initialize(self.currentData);
    }

    self.orientDateLabel();
    // Bars magic defined in ./controls
    self.bars = (self.chart!.append("g") as any).Bars(self);
    self.bars.animate(self.currentData);

    self.renderControls();
    self.drawBoundaries();
    self.initialized = true;
  }

  dimensionChanged(dimension: "x" | "y" | "z" | "color", param: string) {
    if (!this.settings.controls?.enabled)
      throw new Error(
        "Error: Should Not Be Able to Update Dimension Param When Controls Disabled"
      );
    this.params[dimension] = param;

    let points = this.points(); // Cannot Render Axes with Date Filtered Points - Will Cause Issues In Axis Ranges
    this.axes.update(points);
    this.bars.animate(this.currentData);

    this.orientDateLabel();
  }

  hide(point: any) {
    super.hide(point);
    this.currentData = this.points({ date: this.timeCursor }); // Maintain Time Filtered Points for Non Hidden Markers

    let points = this.points(); // Cannot Render Axes with Date Filtered Points - Will Cause Issues In Axis Ranges
    this.axes.update(points);
    this.bars.animate(this.currentData); // Default Behavior for Hide/Show
    this.orientDateLabel();
  }
  // Called from Context Menu - Shows All Bubbles
  showAll() {
    super.showAll();
    this.currentData = this.points({ date: this.timeCursor });

    let points = this.points(); // Cannot Render Axes with Date Filtered Points - Will Cause Issues In Axis Ranges
    this.axes.update(points);
    this.bars.animate(this.currentData); // Default Behavior for Hide/Show
    this.orientDateLabel();
  }
  show(point: any) {
    super.show(point);
    this.currentData = this.points({ date: this.timeCursor });

    let points = this.points(); // Cannot Render Axes with Date Filtered Points - Will Cause Issues In Axis Ranges
    this.axes.update(points);
    this.bars.animate(this.currentData); // Default Behavior for Hide/Show
    this.orientDateLabel();
  }

  // Positions Date Label to Avoid Bars
  orientDateLabel() {
    let yOffset = Constants.Timeline.dateLabel.offset.y + 15.0;
    if (this.axes.x.orientation == "top") {
      yOffset = this.chart!.height - Constants.Timeline.dateLabel.offset.y;
    }
    this.dateLabel
      .transition()
      .duration(this.settings.chart.animationDuration)
      .ease(d3.easeLinear)
      .attr("y", yOffset);
  }

  // Update Bubble Positions and Fund List - Do Not Update Axes - Animate Optionally
  update(options?: any) {
    this.currentData = this.points({ date: this.timeCursor }); // Returns As List of Data Points - Points Do Not Include Hidden Points

    let points = this.points(); // Cannot Render Axes with Date Filtered Points - Will Cause Issues In Axis Ranges
    this.axes.update(points);

    // Possible Issue: If variation in data large enough, fast animation of bars will cause ordering for numerical data on X axis to be too fast to readjust bar order.
    if (options && options.animate) this.bars.animate(this.currentData);
    else this.bars.draw(this.currentData); // Default Behavior

    if (this.settings.fundlist.enabled) {
      this.fundList.renderItems(this.currentData);
    }
    if (this.endDate == this.timeCursor) {
      this.finished();
    }
  }

  focus(point: any, from: any) {
    super.focus(point, from);
    this.bars.focus(point); // Will Also Clear All Focuses From Other Bubbles
  }
  unfocus(point: any, from: any) {
    super.unfocus(point, from);
    this.bars.unfocus(point);
  }
  selectPoint(point: any, from: any) {
    super.selectPoint(point, from);
    this.bars.selectPoint(point);
  }
  deselectPoint(point: any, from: any) {
    super.deselectPoint(point, from);
    this.bars.deselectPoint(point);
  }

  get controlNodes() {
    const result: { label: string; id: string; children: any[] }[] =
      new BarControl(this, this.settings.controls.params).nodes();

    if (this.settings.data.idEnabled) {
      result.forEach(node => {
        node.children = node.children.filter(child => {
          return child.label !== this.settings.data.idColumn;
        });
      });
    }

    return result;
  }

  get controlDimensions() {
    if (this.settings.controls.enabled) {
      return this.settings.controls.params;
    }
    return [];
  }

  static allowedParam(chart: any, dimension: any, param: any) {
    let fakeControls = new BarControl(chart, [dimension]);
    let children = (fakeControls as any)[dimension].children;
    let ids = _.map(children, "id");
    return _.includes(ids, param);
  }

  static defaultParams_(chart: any) {
    if (chart.numeric.length < 1)
      throw new Error("Error: Bar Chart Must Have At Least 1 Numeric Axes");
    return { x: "Name", y: chart.numeric[0], color: "Name" };
  }

  // Just Used for Settings Validation
  static get allowedNodeParents() {
    let parents = ["x", "y", "color"];
    return parents;
  }
}
