import { ParentSettings } from "./parent";

export interface IDataSettings {
  idEnabled?: boolean;
  idColumn?: string;
}

// Settings used to parse\transform the chart's data model
export class DataSettings extends ParentSettings<IDataSettings> {
  idEnabled: boolean;
  idColumn: string;

  constructor(settings?: IDataSettings) {
    //todo: also validate enabled and column properties
    super(settings, [], "Data", {
      idEnabled: "bool",
      idColumn: "string",
    });

    this.idEnabled = true;
    this.idColumn = "id";
  }
}
