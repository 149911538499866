import { ChartSettings, IChartSettings } from "./chart";
import {
  BarControlSettings,
  BubbleControlSettings,
  IControlSettings,
  TransitionControlSettings,
} from "./controls";
import { DataSettings, IDataSettings } from "./data";
import { FundListSettings, IFundListSettings } from "./fundlist";

export interface IBarChartSettings {
  chart?: IChartSettings;
  controls?: IControlSettings;
  data?: IDataSettings;
  fundlist?: IFundListSettings;
}

export class BarChartSettings {
  readonly data: DataSettings;
  readonly chart: ChartSettings;
  readonly controls: BarControlSettings;
  readonly fundlist: FundListSettings;

  constructor(settings: IBarChartSettings) {
    this.data = new DataSettings(settings.data);
    this.chart = new ChartSettings(settings.chart);
    this.controls = new BarControlSettings(settings.controls);
    this.fundlist = new FundListSettings(settings.fundlist);

    for (const key of Object.keys(this) as (keyof BarChartSettings)[]) {
      if (typeof this[key] !== "function") {
        this[key].populate();
      }
    }
  }

  validate(model: any) {
    for (const key of Object.keys(this) as (keyof BarChartSettings)[]) {
      if (typeof this[key] !== "function") {
        this[key].validate(model);
      }
    }
  }
}

export interface IBubbleChartSettings {
  chart?: IChartSettings;
  controls?: IControlSettings;
  data?: IDataSettings;
  fundlist?: IFundListSettings;
}

export class BubbleChartSettings {
  readonly data: DataSettings;
  readonly chart: ChartSettings;
  readonly controls: BubbleControlSettings;
  readonly fundlist: FundListSettings;

  constructor(settings: IBubbleChartSettings) {
    this.data = new DataSettings(settings.data);
    this.chart = new ChartSettings(settings.chart);
    this.controls = new BubbleControlSettings(settings.controls);
    this.fundlist = new FundListSettings(settings.fundlist);

    for (const key of Object.keys(this) as (keyof BubbleChartSettings)[]) {
      if (typeof this[key] !== "function") {
        this[key].populate();
      }
    }
  }

  validate(model: any) {
    for (const key of Object.keys(this) as (keyof BubbleChartSettings)[]) {
      if (typeof this[key] !== "function") {
        this[key].validate(model);
      }
    }
  }
}

export interface ITransitionChartSettings {
  chart?: IChartSettings;
  controls?: IControlSettings;
  data?: IDataSettings;
  fundlist?: IFundListSettings;
}

export class TransitionChartSettings {
  readonly data: DataSettings;
  readonly chart: ChartSettings;
  readonly controls: TransitionControlSettings;
  readonly fundlist: FundListSettings;

  constructor(settings: ITransitionChartSettings) {
    this.data = new DataSettings(settings.data);
    this.chart = new ChartSettings(settings.chart);
    this.controls = new TransitionControlSettings(settings.controls);
    this.fundlist = new FundListSettings(settings.fundlist);

    for (const key of Object.keys(this) as (keyof TransitionChartSettings)[]) {
      if (typeof this[key] !== "function") {
        this[key].populate();
      }
    }
  }

  validate(model: any) {
    for (const key of Object.keys(this) as (keyof TransitionChartSettings)[]) {
      if (typeof this[key] !== "function") {
        this[key].validate(model);
      }
    }
  }
}
