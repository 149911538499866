import * as d3 from "d3";
import _ from "lodash";
import type { GroupArgs } from "../settings/chart";
import InterpPoint from "./interpolated";
import DataParser from "./parse";
import type { DataPoint } from "./point";

export interface IChartDataModelSettings {
  chart: {
    groups?: GroupArgs[];
    params?: {
      x?: string | null;
      y?: string | null;
      z?: string | null;
      color?: string | null;
    };
  };
  fundlist: {
    enabled?: boolean;
  };
}

export class ChartDataModel<TSettings extends IChartDataModelSettings> {
  public readonly raw: any;
  public readonly settings: TSettings;
  public readonly groups?: GroupArgs[];
  public readonly dataTypes: Record<string, string>;
  public readonly numeric: string[];
  public readonly series: DataPoint[];
  public readonly selected: Record<string, boolean>;
  public readonly hidden: Record<string, boolean>;
  public focused: null | string;
  public readonly categoric: string[];

  constructor(raw: any, settings: TSettings) {
    this.raw = raw;
    this.settings = settings;

    // this.highlight = this.settings.chart.highlight.names || []
    this.groups = this.settings.chart.groups;

    // These Are Generated Inside Parser
    this.dataTypes = {}; // Specifies Number, String or Date for Each Column of CSV Data
    this.numeric = []; // Numeric Dimensions
    this.categoric = []; // Categoric Dimensions

    // Parameters That Are Updated During Live Data Configurations
    this.selected = {};
    this.hidden = {};
    this.focused = null;

    this.series = DataParser.parse(this, raw);
    if (this.series.length == 0) {
      throw new Error("Must Provide a Non Empty Array of Valid Points");
    }

    // Default Hidden and Selected Points
    let self = this;
    _.each(this.names, function (name: any) {
      self.selected[name] = false;
      self.hidden[name] = false;
    });
  }

  get names() {
    let names = _.uniq(_.map(this.series, "Name"));
    names.sort();
    return names;
  }

  get dates() {
    let dates = _.uniq(_.map(this.series, "Date"));
    dates.sort(); // VERY Important - Dates Must be Sorted
    return dates;
  }

  get startDate() {
    return _.min(this.dates);
  }

  get endDate() {
    return _.max(this.dates);
  }

  // Returns All Points Filtered by Given Criteria - Default Does Not Include HIdden Points
  points(options?: any) {
    let points = this.series.slice();

    // If Filtering By Date - Need to Interpolate Points
    if (options && options.date !== undefined) {
      points = this.pointsOnDate(options.date); // Will Interpolate If Required
    }

    // Remove Hidden Points
    let self1 = this;
    points = _.filter(points, function (point) {
      return !self1.hidden[point.Name];
    });

    return points;
  }

  // Given a Date, Finds the Left and Right Closest Dates in Sorted Array and Interpolates the Values of the Points on Those Dates
  // Between Each Other
  interpolatePoints(dateToInterp: any) {
    // These Out of Ranges Should Not Happen - d3 Axis Should Restrict These Values
    let self1 = this;
    if (dateToInterp >= this.endDate) {
      let endPoints = _.filter(this.series, function (point: any) {
        return point.Date == self1.endDate;
      });
      return endPoints; // Dont Interpolate We Are At End of List
    } else if (dateToInterp <= this.startDate) {
      let startPoints = _.filter(this.series, function (point: any) {
        return point.Date == self1.startDate;
      });
      return startPoints; // Dont Interpolate We Are At Start of List
    }

    let bisect = d3.bisector(function (date: any) {
      return date;
    }).left;
    let leftInd = bisect(this.dates, dateToInterp);

    if (leftInd > this.dates.length - 1) {
      throw new Error("Error: Index Out of Bounds");
    }

    let leftDate = this.dates[leftInd - 1];
    let rightDate = this.dates[leftInd];

    let startPoints = _.filter(this.series, function (point: any) {
      return point.Date == leftDate;
    });
    let endPoints = _.filter(this.series, function (point: any) {
      return point.Date == rightDate;
    });

    let allNames = _.union(
      _.map(startPoints, "Name"),
      _.map(endPoints, "Name")
    );
    allNames.sort();

    let interpolated: any = [];

    let self = this;
    _.each(allNames, function (name) {
      let first = _.find(startPoints, { Name: name });
      let end = _.find(endPoints, { Name: name });

      // If Start Present but Not End, Use Start, & Vice Versa
      if (first && !end) interpolated.push(first);
      else if (end && !first) interpolated.push(end);
      // Both Have Points with Name - Must Interpolate Between
      else {
        let interpPoint = InterpPoint.interpolatePoints(
          first,
          end,
          dateToInterp,
          self
        );
        interpolated.push(interpPoint);
      }
    });

    return interpolated;
  }

  // Checks if We Have Data On Date - If Not - Interpolates Each Manager in Hierarchy Between Dates
  pointsOnDate(date: any) {
    if (date > this.endDate || date < this.startDate)
      throw new Error("Error: Date Out of Range");

    // Might run into issues here if we are checking for a date where certain managers have data and certain managers do not.  Might have to automatically check all managers
    let pointsByDate = _.groupBy(this.series, function (point) {
      return point.Date;
    });

    // Dont Need to Interpolate if Date Present
    if (pointsByDate[date]) {
      let points = pointsByDate[date];

      // Ensure That Multiple Managers Do Not Exist (Sanity Check)
      let names = _.map(points, "Name");
      if (names.length != _.uniq(names).length)
        throw new Error("Error: Multiple Names Found on Date " + String(date));
      return points;
    }

    // Need to Interpolate
    let interpolated = this.interpolatePoints(date);
    let names = _.map(interpolated, "Name");
    if (names.length != _.uniq(names).length)
      throw new Error("Error: Multiple Names Found on Date " + String(date));

    return interpolated;
  }
}
