import { ASSERTIONS } from "./assertions";
import _ from "lodash";

// Inherited by Sub Settings Components
export abstract class ParentSettings<T extends Partial<Record<string, any>>> {
  constructor(
    readonly settings: T = {} as T,
    public grouped: string[] = [], // TODO readonly?
    readonly __name__?: string,
    readonly validation: Record<
      string,
      keyof typeof ASSERTIONS | [type: keyof typeof ASSERTIONS, options?: any]
    > = {}
  ) {}

  populate(ignoreKey?: string | string[]) {
    const ignore = Array.isArray(ignoreKey)
      ? ignoreKey
      : typeof ignoreKey === "string"
      ? [ignoreKey]
      : undefined;

    const providedKeys = Object.keys(this.settings).filter(key => {
      // Ignore Grouped Elements
      if (this.grouped && _.includes(this.grouped, key)) {
        return false;
      }
      // Ignore Items That We Ignore for Population
      if (ignore && ignore.includes(key)) {
        return false;
      }
      return true;
    });

    const availableKeys = Object.keys(this).filter(key => {
      if (
        key !== "validation" &&
        key !== "__name__" &&
        key !== "settings" &&
        key !== "grouped"
      ) {
        if (key === "hover" || key === "clicked") {
          // Not Putting This In Will Cause Hover or Clicked Params to Throw Error Since They Are Functions
          return true;
        }
        if (this[key as keyof this] instanceof Function) return false;
        return true;
      }
      return false;
    });

    for (const key of providedKeys) {
      // Make Sure Key is Valid
      if (!availableKeys.includes(key))
        throw new Error(
          "Invalid Key " + key + " for " + this.__name__ + " Settings"
        );
      this[key as keyof this] = this.settings[key];
    }
    return this;
  }

  // Model Passed In to Ensure Data Provided is Okay
  // Cant Ignore Validation
  validate(model: any) {
    const availableKeys = Object.keys(this).filter(key => {
      if (key !== "validation" && key !== "__name__" && key !== "settings") {
        if (this[key as keyof this] instanceof Function) return false;
        return true;
      }
      return false;
    });

    for (const key of availableKeys) {
      // Validate Key Against Type/Assertion
      const validation = this.validation?.[key];
      if (validation) {
        const value = this[key as keyof this];
        const [type, options] = Array.isArray(validation)
          ? validation
          : [validation];
        ASSERTIONS[type](key, value, options);
      }
    }
  }
}
