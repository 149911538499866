import { ParentSettings } from "./parent";

export interface IFundListSettings {
  enabled?: boolean;
  width?: number;
}

// Overall Settings Object for Any Chart - Inherited by Specific Chart Settings Objects
export class FundListSettings extends ParentSettings<IFundListSettings> {
  public enabled = true;
  public width = 280;

  constructor(settings?: IFundListSettings) {
    super(settings, [], "FundList", {
      enabled: "bool",
      width: "numeric",
    });
  }
}
