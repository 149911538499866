import * as d3 from "d3";
import _ from "lodash";
import { Constants } from "../constants";
import { NumericalDomain, OriginClamp } from "../utilities/domain";
import Generators from "./generators";

// Right Now - To Make Gridlines Look Better - Axis Is Not Flipped from Top to Bottom
// When Bars Are Negative
const Axis = function (
  this: typeof d3.selection.prototype,
  dim: any,
  axes: any,
  chart: any
) {
  this.dim = dim;
  this.chart = chart;
  this.axes = axes;
  this.domain_ = null; // Default - Will be Set
  this.tickSize = 5.0; // Only Applicable if Gridlines Not Present

  Object.defineProperty(this, "tickPadding", {
    configurable: true,
    get: function param() {
      return 8.0;
    },
  });

  this.renderLabel = function () {
    // Only Render Label if Controls Not Present
    if (!_.includes(this.chart.controlDimensions, "x")) {
      if (!this.label) {
        this.label = this.append("text")
          .attr("text-anchor", "end")
          .attr("class", "axis-label")
          .attr("x", this.labelPosition.x)
          .attr("y", this.labelPosition.y);
      }
      this.label.text(this.param);
    }
  };
};

export const YAxis = function (
  this: typeof d3.selection.prototype,
  axes: any,
  chart: any
) {
  Axis.bind(this)("y", axes, chart);
  this.attr("class", "y-axis");
  this.range = [this.chart.chart.height, 0.0];

  Object.defineProperty(this, "param", {
    configurable: true,
    get: function param() {
      return this.chart.params.y;
    },
  });

  Object.defineProperty(this, "labelPosition", {
    configurable: true,
    get: function param() {
      let position = {
        y: -1.0 * Constants.Axes.y.label.xOffset,
        x: -1.0 * Constants.Axes.y.label.yOffset,
      };
      position.y = position.y - 0.5 * this.tickPadding;
      if (!this.fullGridlines) {
        position.y = position.y - this.tickSize;
      }
      return position;
    },
  });

  this.position = function () {
    this.attr(
      "transform",
      "translate(" + String(0.0) + "," + String(0.0) + ")"
    );
  };
  // Turn Off Gridlines for Now Until Fixed
  this.drawGridLines = function () {
    this.format.tickSize(-1.0 * this.chart.chart.width);
    this.transition().duration(400).ease(d3.easeLinear).call(this.format);
  };
  // Override to Rotate
  this.renderLabel = function () {
    // Only Render Label if Controls Not Present
    if (!_.includes(this.chart.controlDimensions, "y")) {
      if (!this.label) {
        this.label = this.append("text")
          .attr("text-anchor", "end")
          .attr("transform", "rotate(-90)")
          .attr("x", this.labelPosition.x)
          .attr("y", this.labelPosition.y)
          .attr("class", "axis-label");
      }
      this.label.text(this.param);
    }
  };
  return this;
};

const XAxis = function (
  this: typeof d3.selection.prototype,
  axes: any,
  chart: any
) {
  Axis.bind(this)("x", axes, chart);
  this.attr("class", "x-axis");
  this.range = [0.0, this.chart.chart.width]; // Same Regardless of Chart Type

  Object.defineProperty(this, "labelPosition", {
    configurable: true,
    get: function param() {
      let position = {
        x: this.chart.chart.width - Constants.Axes.x.label.xOffset,
        y: Constants.Axes.x.label.yOffset,
      };
      position.y = position.y + 0.5 * this.tickPadding;
      if (!this.fullGridlines) {
        position.y = position.y + this.tickSize;
      }
      return position;
    },
  });

  Object.defineProperty(this, "param", {
    configurable: true,
    get: function param() {
      return this.chart.params.x;
    },
  });

  Object.defineProperty(this, "tickPadding", {
    configurable: true,
    get: function param() {
      return 8.0;
    },
  });

  Object.defineProperty(this, "orientation", {
    configurable: true,
    get: function orientation() {
      let dim1 = this.axes.y.scale.domain()[0];
      let dim2 = this.axes.y.scale.domain()[1];

      let zeroBound = this.axes.y.scale(0.0);
      if (zeroBound < this.axes.y.scale(dim2)) {
        zeroBound = this.axes.y.scale(dim2);
      } else if (zeroBound > this.axes.y.scale(dim1)) {
        zeroBound = this.axes.y.scale(dim1);
      }
      let lowerBound = this.axes.y.scale(dim1);
      let upperBound = this.axes.y.scale(dim2);

      if (Math.abs(lowerBound - zeroBound) < 1.0) {
        return "bottom";
      } else if (Math.abs(upperBound - zeroBound) < 1.0) {
        return "top";
      }
      return "middle";
    },
  });
  // Have to Cap X Axis Position at Edge of Chart Area In Case Y Axis Origin Beyond Edge of Chart Area
  this.position = function () {
    let yPos = this.chart.axes.y.scale(0.0);

    if (this.orientation == "top") {
      let min = this.chart.axes.y.scale.domain()[1];
      let ymin = this.chart.axes.y.scale(min);
      yPos = Math.max(yPos, ymin);
    } else if (this.orientation == "bottom") {
      let min = this.chart.axes.y.scale.domain()[0]; // Bottom Domain Point
      let ymin = this.chart.axes.y.scale(min);
      yPos = Math.min(yPos, ymin);
    }
    this.attr("transform", "translate(0," + this.chart.chart.height + ")"); // Set X Axis to Origin of Y Axis
  };

  return this;
};

export const CoordinateAxis = function (
  this: typeof d3.selection.prototype,
  dim?: any
) {
  this.dim = dim;

  this.fullGridlines = false;
  if (this.chart.settings.chart.gridlines) this.fullGridlines = true;

  return this;
};

export const CategoryAxis = function (
  this: typeof d3.selection.prototype,
  axes: any,
  chart: any
) {
  XAxis.bind(this)(axes, chart);
  this.__name__ = "CategoryAxis";

  this.fullGridlines = false;

  // This is For When We Merge from CoordinateX to CategoryX - Label Handled by FakeCategoryAxis
  if (this.label) this.label.remove();

  // Hides Category Axis Ticks - Ticks Are Displayed with 'Fake' Axis
  this.formatTicks = function () {
    this.format.tickFormat("");
  };
  // Need to Include to Override XCoordinateAxis When Transition Chart Changed
  this.drawGridLines = function () {
    this.format.tickSize(0.0);
    return;
  };

  Object.defineProperty(this, "domain", {
    configurable: true,
    get: function domain() {
      return this.domain_;
    },
    set: function domain(value) {
      let dataType = this.chart.dataTypes[this.param];

      // For X Param - Must Initially Sort the Values in Ascending Order, but When Using the Scale, We Need to Maintain the Order of Managers
      this.domain_ = value;
      if (dataType != "numeric") {
        this.domain_.sort(); // Alphabetize Domain IMPORTANT - Only Sort if Not Numeric
      }
    },
  });

  Object.defineProperty(this, "scale", {
    configurable: true,
    get: function scale() {
      return d3
        .scaleBand()
        .rangeRound(this.range)
        .padding(0.1)
        .domain(this.domain);
    },
  });

  this.setDomain = function (points: any) {
    let self = this;

    let dataType = this.chart.dataTypes[this.param];
    // Numeric Category Axis - Sort by Parameter Value
    if (dataType == "numeric") {
      let points = this.chart.points({ date: this.chart.timeCursor });
      points.sort(function (a: any, b: any) {
        return a[self.param] - b[self.param];
      });
      this.domain = _.map(points, "Name");
    } else {
      this.domain = _.uniq(_.map(points, this.param));
    }
  };

  // Points Contains All Points - Not Points Filtered on Date
  this.update = function (points: any) {
    this.setDomain(points);

    this.format.scale(this.scale);
    this.format.tickPadding(this.tickPadding);

    this.position(); // Reorient Axis Position
    this.formatTicks();
    this.drawGridLines();

    // Recalling With Scale Not Completely Necessary But We Need the Crosshairs to Line Up Exactly After Update
    this.transition().duration(400).ease(d3.easeLinear).call(this.format);
  };
  // For Category Axis - Only Draw Gridlines on First Render for X Axis - Updating Constantly
  // No Label for Category Axis - Fake Axis Handles Label
  this.initialize = function (points: any) {
    this.setDomain(points); // Set Domain First to Create a Scale

    this.format = d3.axisBottom(this.scale);
    this.format.tickPadding(this.tickPadding);

    this.position(); // Reorient Axis Position
    this.formatTicks();
    this.drawGridLines();

    this.transition().duration(400).ease(d3.easeLinear).call(this.format);
  };

  return this;
};

export const FakeCategoryAxis = function (
  this: typeof d3.selection.prototype,
  axes: any,
  chart: any
) {
  XAxis.bind(this)(axes, chart);
  this.__name__ = "FakeCategoryAxis";

  this.fullGridlines = false;

  // Need to Include to Override XCoordinateAxis When Transition Chart Changed
  this.drawGridLines = function () {
    this.format.tickSize(this.tickSize);
    return;
  };

  // Hides Specific Ticks for Axes Depending on Chart Type
  this.formatTicks = function () {
    let dataType = this.chart.dataTypes[this.param];

    if (dataType != "numeric") {
      this.format.tickFormat("");
      return;
    }
    // Label Only Present When Controls Missing
    if (this.label) {
      let labelW = this.label.node().getBBox().width;
      let labelX = this.scale(_.last(this.scale.domain()));
      let labelLeft = labelX - labelW - 10.0; // Add Padding

      let self = this;
      this.format.tickFormat(function (d: any) {
        let format = "";

        let xposition = self.scale(d);
        if (parseFloat(xposition) < labelLeft) {
          return d.toFixed(2);
        }
        return "";
      });
    }
  };

  Object.defineProperty(this, "domain", {
    configurable: true,
    get: function domain() {
      return this.domain_;
    },
    set: function domain(value) {
      if (!this.range)
        throw new Error("Must Provide Range Before Setting Domain");
      this.domain_ = value;
    },
  });

  Object.defineProperty(this, "scale", {
    configurable: true,
    get: function scale() {
      return d3
        .scaleLinear()
        .domain(this.domain)
        .nice()
        .range(this.range)
        .nice();
    },
  });

  this.setDomain = function (points: any) {
    let dataType = this.chart.dataTypes[this.param];

    if (dataType == "numeric") {
      // Use All Values to Generate Domain
      let points = this.chart.points({ date: this.chart.timeCursor });
      let values: any = _.map(points, this.param);
      this.domain = NumericalDomain(values);
    } else {
      this.domain = [0.0, 1.0];
    }
  };

  // Points Contains All Points - Not Points Filtered on Date
  this.update = function (points: any) {
    this.setDomain(points);

    this.format.scale(this.scale);
    this.format.tickPadding(this.tickPadding);

    this.position(); // Reorient Axis Position
    this.renderLabel();
    this.formatTicks();

    // Recalling With Scale Not Completely Necessary But We Need the Crosshairs to Line Up Exactly After Update
    this.transition().duration(400).ease(d3.easeLinear).call(this.format);
  };

  this.initialize = function (points: any) {
    this.setDomain(points); // Set Domain First to Create a Scale

    this.format = d3.axisBottom(this.scale);
    this.format.tickPadding(this.tickPadding);

    this.position(); // Reorient Axis Position
    this.renderLabel();
    this.formatTicks();
    this.drawGridLines();

    this.transition().duration(400).ease(d3.easeLinear).call(this.format);
  };
  return this;
};

export const XCoordinateAxis = function (
  this: typeof d3.selection.prototype,
  axes: any,
  chart: any
) {
  XAxis.bind(this)(axes, chart);
  CoordinateAxis.bind(this)();
  this.numTicks = 10;
  this.__name__ = "XCoordinateAxis";

  // Only Required on Initial Render and When Chart Toggles State
  // Turn Off Gridlines for Now Until Fixed
  this.drawGridLines = function () {
    this.format.tickSize(-1.0 * this.chart.chart.height);
  };

  // Hides Specific Ticks for Axes Depending on Chart Type
  this.formatTicks = function () {
    // Label Only Present When Controls Missing
    if (this.label) {
      let labelW = this.label.node().getBBox().width;
      let labelX = this.scale(this.scale.domain()[1]);
      let labelLeft = labelX - labelW - 10.0; // Add Padding

      let self = this;
      this.format.tickFormat(function (d: any) {
        let format = d;

        let xposition = self.scale(d);
        if (parseFloat(xposition) < labelLeft) {
          return format;
        }
        return "";
      });
    }
  };

  Object.defineProperty(this, "domain", {
    configurable: true,
    get: function domain() {
      return this.domain_;
    },
    set: function domain(value) {
      if (!this.range)
        throw new Error("Must Provide Range Before Setting Domain");
      this.domain_ = value;
    },
  });

  Object.defineProperty(this, "scale", {
    configurable: true,
    get: function scale() {
      return d3
        .scaleLinear()
        .domain(this.domain)
        .nice()
        .range(this.range)
        .nice();
    },
  });

  this.setDomain = function (points: any) {
    if (!_.includes(this.chart.numeric, this.param)) {
      throw new Error("Error: Coordinate Axis Must be Numeric");
    }
    // Use All Values to Generate Domain
    let values: any = _.map(points, this.param);
    values.sort();
    this.domain = NumericalDomain(values);
  };

  // Points Contains All Points - Not Points Filtered on Date
  this.update = function (points: any) {
    this.setDomain(points);

    this.format.scale(this.scale);
    this.format.tickPadding(this.tickPadding);
    if (this.chart.settings.chart.gridlines) this.drawGridLines(); // Necessary Otherwise Axes Gridlines Wont Adjust for State - Gridlines Toggle for Numeric/Non Numeric X Params

    // Recalling With Scale Not Completely Necessary But We Need the Crosshairs to Line Up Exactly After update
    this.position(); // Reorient Axis Position
    this.renderLabel();
    this.formatTicks();

    this.transition().duration(400).ease(d3.easeLinear).call(this.format);
  };

  this.initialize = function (points: any) {
    this.setDomain(points); // Set Domain First to Create a Scale

    this.format = d3.axisBottom(this.scale);
    this.format.tickPadding(this.tickPadding);
    this.format.ticks(this.numTicks);

    // Gridlines Only Required on Render
    if (this.chart.settings.chart.gridlines) this.drawGridLines();

    this.position(); // Reorient Axis Position
    this.renderLabel();
    this.formatTicks();

    this.transition().duration(400).ease(d3.easeLinear).call(this.format);
  };
  return this;
};

export const YCoordinateAxis = function (
  this: typeof d3.selection.prototype,
  axes: any,
  chart: any
) {
  YAxis.bind(this)(axes, chart);
  CoordinateAxis.bind(this)();
  this.numTicks = 10;
  this.__name__ = "YCoordinateAxis";

  // Hides Specific Ticks for Axes Depending on Chart Type
  this.formatTicks = function () {
    // Label Only Present When Controls Missing
    if (this.label) {
      let labelH = this.label.node().getBBox().width;
      let labelY = this.scale(this.scale.domain()[1]);
      let labelBottom = labelH + labelY + 10.0; // Add Padding

      let self = this;
      this.format.tickFormat(function (d: any) {
        let format = d;

        let yposition = self.scale(d);
        if (parseFloat(yposition) > labelBottom) {
          return format;
        }
        return "";
      });
    }
  };

  Object.defineProperty(this, "domain", {
    configurable: true,
    get: function domain() {
      return this.domain_;
    },
    set: function domain(value) {
      if (!this.range)
        throw new Error("Must Provide Range Before Setting Domain");
      if (!value) throw new Error("Cannot Set Null Domain");
      this.domain_ = value;
    },
  });

  Object.defineProperty(this, "scale", {
    configurable: true,
    get: function scale() {
      return d3
        .scaleLinear()
        .domain(this.domain)
        .nice()
        .range(this.range)
        .nice();
    },
  });

  this.setDomain = function (points: any) {
    if (!_.includes(this.chart.numeric, this.param)) {
      throw new Error("Error: Coordinate Axis Must be Numeric");
    }
    // Need to Set Domain First to Get Valid Scale
    let values = _.map(points, this.param);
    values.sort();
    this.domain = NumericalDomain(values);
  };

  // Points Contains All Points - Not Points Filtered on Date
  this.update = function (points: any) {
    this.setDomain(points);
    this.format.scale(this.scale);

    // Recalling With Scale Not Completely Necessary But We Need the Crosshairs to Line Up Exactly After update
    this.position(); // Reorient Axis Position
    this.renderLabel();
    this.formatTicks();

    this.transition().duration(400).ease(d3.easeLinear).call(this.format);
  };

  this.initialize = function (points: any) {
    this.setDomain(points); // Set Domain First to Create a Scale

    this.format = d3.axisLeft(this.scale).tickPadding(8.0);
    this.format.ticks(this.numTicks);

    this.position(); // Reorient Axis Position
    this.renderLabel();
    this.formatTicks();

    // Only Required on Initial Render
    if (this.chart.settings.chart.gridlines) this.drawGridLines();

    this.transition().duration(400).ease(d3.easeLinear).call(this.format);
  };
  return this;
};

// Bar Y Coordinate Axis - Only Major Difference is Values Are Clamped in Y Dimension to 0.0 if Both Positive
export const BarYCoordinateAxis = function (
  this: typeof d3.selection.prototype,
  axes: any,
  chart: any
) {
  YCoordinateAxis.bind(this)(axes, chart);

  this.setDomain = function (points: any) {
    if (!_.includes(this.chart.numeric, this.param)) {
      throw new Error("Error: Coordinate Axis Must be Numeric");
    }
    // Need to Set Domain First to Get Valid Scale
    let values: any = _.map(points, this.param);
    values.sort();
    let domain = NumericalDomain(values);
    this.domain = OriginClamp(domain);
  };
  return this;
};

export const ZCoordinateAxis: any = function (
  this: any,
  axes: any,
  chart: any
): void {
  this.dim = "z";
  this.axes = axes;
  this.chart = chart;

  this.domain_ = null;
  this.scale = null;
  this.range = [Constants.Axes.size.min, Constants.Axes.size.max];

  Object.defineProperty(this, "param", {
    configurable: true,
    get: function param() {
      return this.chart.params.z;
    },
  });

  Object.defineProperty(this, "domain", {
    configurable: true,
    get: function domain() {
      return this.domain_;
    },
    set: function domain(value) {
      if (value == null && this.param != "same")
        throw new Error("Cannot Set Null Domain for Non Same Param");
      if (this.param == "same" && value != null)
        throw new Error("Cannot Set Domain to Non Null for Same Param");

      if (value != null) {
        this.domain_ = value;
        this.scale = d3
          .scaleLinear()
          .domain(this.domain_)
          .nice()
          .range(this.range)
          .nice();
      } else {
        this.scale = function (index: any) {
          return Constants.Axes.size.sameSizeRadius;
        };
      }
    },
  });

  this.setDomain = function (points: any) {
    if (this.param == "same")
      throw new Error("Cannot Set Domain for Same Parameter");
    if (!_.includes(this.chart.numeric, this.param))
      throw new Error("Error: Size Axis Must be Numeric");

    // Use All Values to Generate Domain
    let values = _.map(points, this.param);
    values.sort();
    this.domain = [_.min(values), _.max(values)];
  };

  // Points Contains All Points - Not Points Filtered on Date
  this.update = function (points: any) {
    if (this.param == "same") {
      this.domain = null;
      return;
    }
    this.setDomain(points);
  };

  this.initialize = function (points: any) {
    if (this.param == "same") {
      this.domain = null;
      return;
    }
    this.setDomain(points);
  };
  return this;
};

export const TimeAxis: any = function (this: any, axes: any, chart: any): void {
  this.dim = "time";
  this.axes = axes;
  this.chart = chart;

  this.domain = [this.chart.startDate, this.chart.endDate];

  this.range = [
    Constants.Timeline.button.width +
      Constants.Timeline.button.margins["left"] +
      Constants.Timeline.button.margins["right"],
    this.chart.timeline.width - Constants.Timeline.slider.width,
  ];

  this.scale = d3
    .scaleLinear()
    .domain(this.domain)
    .range(this.range)
    .clamp(true);
};

// For Color Axis - Keep Track of Non Numerical Param Colors So We Resuse These to Stay Consistent
export const ColorAxis: any = function (
  this: any,
  axes: any,
  chart: any
): void {
  this.dim = "color";
  this.axes = axes;
  this.chart = chart;

  this.domain = null;
  this.scale = null;
  this.tracked = {}; // Indexed by Params and then Point IDs/Names

  Object.defineProperty(this, "param", {
    configurable: true,
    get: function param() {
      return this.chart.params.color;
    },
  });
  // Dont Auto Set Scale with Object Property - Manually Set When Domain Updated
  this.setDomain = function (points: any) {
    if (this.param == "same") {
      this.domain_ = null; // Cant Set Domain Directly to Null

      // To Do: Make This So Focused Managers Are Gray?
      this.scale = function (value: any) {
        return Constants.Axes.color.sameColor;
      };
    } else {
      let dataType = this.chart.dataTypes[this.param];

      // Create Generator Instance Based on Data Type
      let generator;
      if (dataType != "numeric") {
        // Filter Points by Date for Color Scale Non Numeric Param - Must be Unique - Will Throw Error if Not Unique
        let points = this.chart.points({ date: this.chart.timeCursor });
        let values = _.map(points, this.param);
        values = _.uniq(values); // Can Have Non Unique Values (Think Strategy for Manager)

        generator = new Generators.DiscreteColorGenerator(this, values);
        this.scale = generator.scale;
      } else {
        // Use All Values to Generate Domain
        let values = _.map(points, this.param);
        values.sort();
        this.domain = NumericalDomain(values);

        generator = new Generators.ContinuousColorGenerator(this, this.domain);
        this.scale = generator.scale;
      }
    }
  };
  this.initialize = function (points: any) {
    this.setDomain(points);
  };

  // Points Contains All Points - Not Points Filtered on Date
  this.update = function (points: any) {
    this.setDomain(points);
  };
};
