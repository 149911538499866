import bubbleChartImage from "../static/images/bubblechart.png";
import barChartImage from "../static/images/barchart.png";
import playImage from "../static/images/play.png";
import pauseImage from "../static/images/pause.png";
import caretImage from "../static/images/caret.png";
import _ from "lodash";

const ChartConstants = {
  labels: {
    maxWords: 3,
    padding: 4.0,
  },
  toggle: {
    height: 25,
    width: 42.0,
    bubble: bubbleChartImage,
    bar: barChartImage,
  },
};

const ScrollConstants = {
  width: 15.0,
  margins: { left: 3.0, right: 3.0 },
  slider: { rx: 2.0, ry: 2.0, height: 15.0 },
};

const DropdownConstants = {
  height: 26.0,
  rx: 2.0,
  ry: 2.0,
  caret: {
    link: caretImage,
    size: {
      width: 11,
      height: 9,
    },
  },
  item: {
    height: 22.0,
    spacing: 0.0,
    padding: 2.0,
  },
};

const AxesConstants = {
  x: { label: { yOffset: 11, xOffset: 2 } },
  y: { label: { yOffset: 2, xOffset: 7 } },
  color: { sameColor: "#6baed6" },
  size: { sameSizeRadius: 12, min: 8, max: 40 },
  control: {
    height: 32.0,
  },
};

const TimelineConstants = {
  playButton: playImage,
  pauseButton: pauseImage,

  overlay: { height: 40 },
  height: 45, // Height Includes Any Margins That Will Offset
  slider: {
    width: 22,
    height: 20,
    rx: "5",
    ry: "10",
  },
  button: {
    height: 40,
    width: 40,
    margins: { right: 5, left: 3 },
    color: "rgba(35, 130, 247, 0.75)",
  },
  dateLabel: {
    offset: {
      x: 5.0,
      y: 10.0,
    },
  },
  date_format: "mm/dd/yyyy",
  granularity: "day",
};

const FundListConstants = {
  spacing: 3.0,
  item: {
    textColor: "#444",
    height: 20,
    padding: 2.0,
  },
};

const MarkerLabelConstants = {
  maxWords: 3,
  padding: 4.0,
  border: true,
};

export const Constants = {
  Scroll: ScrollConstants,
  MarkerLabel: MarkerLabelConstants,
  Dropdown: DropdownConstants,
  FundList: FundListConstants,
  Timeline: TimelineConstants,
  Axes: AxesConstants,
  Chart: ChartConstants,
};
