import { useContext } from "react";
import { useFormState } from "react-hook-form";
import ContentItems from "../ContentItems.component";
import { useTemplateController } from "../Field/FieldContext";
import { ContentItem, FieldContentItem } from "../types";
import { TemplateContext } from "../Context";

export default function ConditionalDisplaySection({
  content,
  conditionalElement,
}: {
  content: ContentItem[];
  conditionalElement: FieldContentItem;
}) {
  const { ownerType, editMode, readFieldFromCache } =
    useContext(TemplateContext);

  const conditionaFieldKey = conditionalElement.key;

  const fieldController = editMode
    ? useTemplateController({ name: conditionaFieldKey }).field
    : null;

  const conditionalField = editMode
    ? fieldController?.value
    : readFieldFromCache(conditionaFieldKey)?.field;

  if (editMode) {
    const { dirtyFields } = useFormState();

    console.log(JSON.stringify(dirtyFields));
  }

  return (
    <div>
      <div>
        <ContentItems
          content={
            conditionalField && !conditionalField.value
              ? content.filter(
                  cf => cf.type === "field" && cf.key === conditionaFieldKey
                )
              : content
          }
        />
      </div>
    </div>
  );
}
