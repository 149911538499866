import _ from "lodash";
import { BaseChart } from "./base_chart";

function nodes(dim: string) {
  switch (dim) {
    case "x":
      return { label: "X Axis", id: "x" };
    case "y":
      return { label: "Y Axis", id: "y" };
    case "z":
      return { label: "Size", id: "z" };
    case "color":
      return { label: "Color", id: "color" };
    default:
      throw new Error("Invalid Dimension: " + dim);
  }
}

export class ControlChild {
  id: any;
  label: any;

  constructor(param: any, label?: any) {
    this.id = param;
    this.label = param;
    if (label) {
      this.label = label;
    }
  }
}

function NumericChildren(array: any, chart: any) {
  array.chart = chart;

  let self = array;
  _.each(array.chart.numeric, function (param: any) {
    let child = new ControlChild(param);
    self.push(child);
  });
  return array;
}

function CategoricChildren(array: any, chart: any) {
  array.chart = chart;

  let self = array;
  _.each(array.chart.categoric, function (param: any) {
    let child = new ControlChild(param);
    self.push(child);
  });
  return array;
}

const ControlNodeChildren: any = function (this: any) {
  this.x = function (chart: any) {
    return NumericChildren([], chart);
  };

  this.y = function (chart: any) {
    return NumericChildren([], chart);
  };

  this.color = function (chart: any) {
    let children = NumericChildren([], chart);
    CategoricChildren(children, chart);

    let additional = new ControlChild("same", "Same Color");
    children.push(additional);
    return children;
  };
  return this;
};

ControlNodeChildren.prototype.Bubble = function () {
  this.z = function (chart: any) {
    let children = NumericChildren(new Array(), chart);
    let additional = new ControlChild("same", "Same Size");
    children.push(additional);
    return children;
  };
  return this;
};

ControlNodeChildren.prototype.Bar = function () {
  this.x = function (chart: any) {
    let children = NumericChildren(new Array(), chart);
    let nameAdditional = new ControlChild("Name");
    children.push(nameAdditional);
    return children;
  };
  return this;
};

// Excludes Dimensions from Controls
export class BarControl {
  dimensions: ("x" | "y" | "z" | "color")[];

  constructor(
    chart: BaseChart<any>,
    dimensions: ("x" | "y" | "z" | "color")[]
  ) {
    let factory = new ControlNodeChildren().Bar();
    this.dimensions = dimensions;

    // Generate Nodes for Controls
    for (const dim of this.dimensions) {
      (this as any)[dim] = nodes(dim);
      (this as any)[dim].children = factory[dim](chart);
    }
  }

  length() {
    return Object.keys(this).length;
  }

  nodes(): { label: string; id: string; children: any[] }[] {
    return this.dimensions.map(dim => (this as any)[dim]);
  }
}

// Excludes Dimensions from Controls
export class BubbleControl {
  dimensions: string[];

  constructor(chart: BaseChart<any>, dimensions: string[]) {
    const factory = new ControlNodeChildren().Bubble();
    this.dimensions = dimensions;

    // Generate Nodes for Controls
    for (const dim of this.dimensions) {
      (this as any)[dim] = nodes(dim);
      (this as any)[dim].children = factory[dim](chart);
    }
  }

  length() {
    return Object.keys(this).length;
  }

  nodes() {
    return this.dimensions.map(dim => (this as any)[dim]);
  }
}
