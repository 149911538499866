export class Dimension extends Object {
  public x: [number, number];
  public y: [number, number];

  constructor() {
    super();
    this.x = [0.0, 0.0];
    this.y = [0.0, 0.0];
  }

  y1(value: number) {
    this.y[1] = value;
  }

  y0(value: number) {
    this.y[0] = value;
  }

  x1(value: number) {
    this.x[1] = value;
  }

  x0(value: number) {
    this.x[0] = value;
  }

  get width() {
    return this.x[1] - this.x[0];
  }

  get height() {
    const height = this.y[1] - this.y[0];
    if (isNaN(height)) {
      throw new Error("NANN");
    }
    return height;
  }
}
