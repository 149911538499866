import * as d3 from "d3";
import _ from "lodash";
import type { BaseChart } from "./base_chart";
import { Constants } from "./constants";

const PlacerRect = function (
  this: typeof d3.selection.prototype,
  chart: any,
  params: any,
  frame: any
) {
  this.chart = chart;

  let self = this;

  this.rect = this.append("rect")
    .attr("id", _.uniqueId(frame.class + "-"))
    .style("fill", "none")
    .attr(
      "transform",
      "translate(" + String(params.x) + "," + String(params.y) + ")"
    )
    .attr("class", function () {
      if (self.chart.settings.chart.showBoundaries) {
        return "placer-rect";
      }
      return "placer-rect-invisible";
    })
    .attr("width", function () {
      return params.width;
    })
    .attr("height", function () {
      return params.height;
    });
};

interface LayoutFrameParams {
  margins?: {
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
  };
  x0i?: number;
  y0i?: number;
  heighti: number;
  widthi: number;
}

class LayoutFrame {
  public class: string;
  public Chart: BaseChart<any>;
  public margins?: {
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
  };
  public x0i: number;
  public y0i: number;
  public heighti: number;
  public height: number;
  public widthi: number;
  public width: number;
  public x1i: number;
  public y1i: number;
  public x0: number;
  public y0: number;
  public x1: number;
  public y1: number;

  constructor(params: LayoutFrameParams, chart: BaseChart<any>, cls: string) {
    this.class = cls;
    this.Chart = chart;

    this.margins = params.margins;
    if (!this.margins) this.margins = {};

    if (!this.margins.left) this.margins.left = 0.0;
    if (!this.margins.right) this.margins.right = 0.0;
    if (!this.margins.bottom) this.margins.bottom = 0.0;
    if (!this.margins.top) this.margins.top = 0.0;

    // Outer Dimensions Without Margins
    this.x0i = params.x0i || 0.0;
    this.y0i = params.y0i || 0.0;

    this.heighti = params.heighti;

    this.height = this.heighti - this.margins.top - this.margins.bottom;

    if (!this.height) {
      throw new Error("Error: Height is NaN");
    }
    this.widthi = params.widthi;
    this.width = this.widthi - this.margins.left - this.margins.right;

    this.x1i = this.x0i + this.widthi;
    this.y1i = this.y0i + this.heighti;

    this.x0 = this.x0i + this.margins.left;
    this.y0 = this.y0i + this.margins.top;

    this.x1 = this.x0 + this.width;
    this.y1 = this.y0 + this.height;
  }

  Frame(selection: any) {
    selection.attr(
      "transform",
      "translate(" + String(this.x0) + "," + String(this.y0) + ")"
    );
    selection.attr("class", this.class);
    _.extend(selection, this);
  }

  DrawBoundaries(selection: any) {
    PlacerRect.bind(selection)(
      this.Chart,
      {
        x: 0.0, // Dont Translate Boundaries - They Are Inside Translated Group
        y: 0.0, // Dont Translate Boundaries - They Are Inside Translated Group
        height: this.height,
        width: this.width,
      },
      this
    );
  }
}

class YAxisControlFrame extends LayoutFrame {
  constructor(chartArea: any, chart: any, extraMarginTop: any) {
    if (!extraMarginTop) extraMarginTop = 0.0;

    let params = {
      margins: YAxisControlFrame.margins(extraMarginTop),
      widthi: YAxisControlFrame.widthi(extraMarginTop),
      x0i: 0.0,
      y0i: 0.0,
      heighti: chartArea.height,
    };

    if (_.includes(chart.controlDimensions, "y")) {
      params.heighti = params.heighti - XAxisControlFrame.heighti;
    }
    super(params, chart, "y-axis-control");
  }
  // Temporarily Adjusting Margins by Variables Otherwise Controlled by Chart Size
  static margins(extraMarginTop: any) {
    return {
      left: 5.0,
      right: 5.0,
      top: 5.0 + extraMarginTop,
      bottom: 5.0 + 20.0,
    };
  }
  static widthi(extraMarginTop: any) {
    return (
      Constants.Axes.control.height +
      YAxisControlFrame.margins(extraMarginTop).left +
      YAxisControlFrame.margins(extraMarginTop).right
    );
  }
}

class XAxisControlFrame extends LayoutFrame {
  constructor(chartArea: any, chart: any, extraMarginTop: any) {
    let params = {
      margins: XAxisControlFrame.margins,
      heighti: XAxisControlFrame.heighti,
      widthi: chartArea.width,
      x0i: 0.0,
      y0i: chartArea.height - XAxisControlFrame.heighti,
    };
    if (_.includes(chart.controlDimensions, "y")) {
      params.x0i = YAxisControlFrame.widthi(extraMarginTop);
      params.widthi = params.widthi - YAxisControlFrame.widthi(extraMarginTop);
    }
    super(params, chart, "x-axis-control");
  }
  static get margins() {
    return { left: 5.0 + 20.0, right: 10.0, top: 5.0, bottom: 5.0 };
  }
  static get heighti() {
    return (
      Constants.Axes.control.height +
      XAxisControlFrame.margins.bottom +
      XAxisControlFrame.margins.top
    );
  }
}

class TimelineFrame extends LayoutFrame {
  constructor(left: any, chart: any) {
    let params = {
      margins: TimelineFrame.margins,
      widthi: left.width,
      x0i: 0.0,
      y0i:
        left.height -
        Constants.Timeline.height -
        TimelineFrame.margins.top -
        TimelineFrame.margins.bottom,
      heighti:
        Constants.Timeline.height +
        TimelineFrame.margins.top +
        TimelineFrame.margins.bottom,
    };
    super(params, chart, "timeline");
  }
  static get margins() {
    return { left: 10.0, right: 15.0, top: 5.0, bottom: 5.0 };
  }
}

// X and Y Axis Sizes Are Dependent on Margins of Chart - i.e. Chart is Generate First and Depending on How Much Space is Available the Controls
// Are Put In Afterwards
class ChartFrame extends LayoutFrame {
  constructor(
    chartArea: any,
    xAxis: any,
    yAxis: any,
    timeline: any,
    chart: any,
    extraMarginTop: any
  ) {
    let params = {
      margins: ChartFrame.margins(extraMarginTop),
      x0i: 0.0,
      y0i: 0.0,
      widthi: chartArea.width,
      heighti: chartArea.height,
    };

    if (_.includes(chart.controlDimensions, "y")) {
      params.widthi = params.widthi - YAxisControlFrame.widthi(extraMarginTop);
      params.x0i = params.x0i + YAxisControlFrame.widthi(extraMarginTop);
    }
    if (_.includes(chart.controlDimensions, "x")) {
      params.heighti = params.heighti - XAxisControlFrame.heighti;
    }

    super(params, chart, "chart");
  }
  static margins(extraMarginTop: any) {
    return { left: 35.0, right: 5.0, top: 5.0 + extraMarginTop, bottom: 25.0 };
  }
}

class ChartAreaFrame extends LayoutFrame {
  public xAxis: XAxisControlFrame;
  public yAxis: YAxisControlFrame;
  public chart: ChartFrame;

  constructor(left: any, timeline: any, chart: any) {
    let params = {
      margins: ChartAreaFrame.margins,
      x0i: 0.0,
      widthi: left.width,
      heighti: left.heighti - timeline.heighti,
    };
    super(params, chart, "chart-area");

    let extraMarginTop = 0.0;
    if (chart.chartType == "TransitionChart")
      extraMarginTop = Constants.Chart.toggle.height;

    this.xAxis = new XAxisControlFrame(this, chart, extraMarginTop);
    this.yAxis = new YAxisControlFrame(this, chart, extraMarginTop);
    this.chart = new ChartFrame(
      this,
      this.xAxis,
      this.yAxis,
      timeline,
      chart,
      extraMarginTop
    );
  }
  static get margins() {
    return { left: 5.0, right: 5.0, top: 5.0, bottom: 10.0 };
  }
}

class RightMenuFrame extends LayoutFrame {
  constructor(right: any, chart: any) {
    let params = {
      margins: RightMenuFrame.margins,
      x0i: 0.0,
      y0i: 0.0,
      widthi: right.width,
      heighti: 0.0, // PlaceHolder Until Appropriate Height Determined
    };

    if (
      _.includes(chart.controlDimensions, "color") &&
      _.includes(chart.controlDimensions, "z")
    ) {
      params.heighti = 110.0;
    } else if (
      _.includes(chart.controlDimensions, "color") ||
      _.includes(chart.controlDimensions, "z")
    ) {
      params.heighti = 55.0;
    }
    super(params, chart, "right-menu");
  }
  static get heightPerDim() {
    return 55.0;
  }
  static height(dimensions: any) {
    let num = 0;
    if (_.includes(dimensions, "color")) num = num + 1;
    if (_.includes(dimensions, "z")) num = num + 1;
    return num * RightMenuFrame.heightPerDim;
  }
  static get margins() {
    return { left: 5.0, right: 5.0, top: 5.0, bottom: 5.0 };
  }
}

class FundListFrame extends LayoutFrame {
  constructor(right: any, rightMenu: any, chart: any) {
    let params = {
      margins: FundListFrame.margins,
      x0i: 0.0, // Margins Indicate Origin Since This Frame Inside Transformed Group
      y0i: 0.0, // Regardless of Color/Z Controls, Still Need Spacing at Top to Line Up With Chart
      widthi: right.width,
      heighti: right.height,
    };
    let rightMenuHeight = RightMenuFrame.height(chart.controlDimensions);
    params.heighti = params.heighti - rightMenuHeight;
    params.y0i = params.y0i + rightMenuHeight;

    super(params, chart, "fundlist");
  }
  static get margins() {
    return { left: 5.0, right: 5.0, top: 10.0, bottom: 5.0 };
  }
}

class LeftFrame extends LayoutFrame {
  public timeline: TimelineFrame;
  public chartArea: ChartAreaFrame;
  constructor(parent: any, chart: any) {
    let params = {
      margins: LeftFrame.margins,
      x0i: 0.0,
      y0i: 0.0,
      heighti: parent.height,
      widthi: chart.settings.chart.width,
    };

    if (chart.settings.fundlist.enabled) {
      params.widthi = params.widthi - chart.settings.fundlist.width;
      if (params.widthi < 0.0) {
        throw new Error(
          "Error: Chart Width Must be Greater Than Fund List Width"
        );
      }
    }
    super(params, chart, "leftg");
    this.timeline = new TimelineFrame(this, chart);
    this.chartArea = new ChartAreaFrame(this, this.timeline, chart);
  }
  static get margins() {
    return { left: 5.0, right: 2.0, top: 5.0, bottom: 5.0 };
  }
}

class RightFrame extends LayoutFrame {
  public readonly rightmenu: RightMenuFrame;
  public readonly fundlist: FundListFrame;

  constructor(parent: ParentFrame, left: LeftFrame, chart: BaseChart<any>) {
    const params = {
      margins: RightFrame.margins,
      x0i: left.widthi,
      y0i: 0.0,
      widthi: chart.settings.fundlist.width,
      heighti: parent.height,
    };

    super(params, chart, "rightg");
    this.rightmenu = new RightMenuFrame(this, chart);
    this.fundlist = new FundListFrame(this, this.rightmenu, chart);
  }

  static get margins() {
    return { left: 2.0, right: 5.0, top: 5.0, bottom: 5.0 };
  }
}

class ParentFrame extends LayoutFrame {
  public left: LeftFrame;
  public right: RightFrame;

  constructor(chart: BaseChart<any>) {
    let params = {
      margins: ParentFrame.margins,
      x0i: 0.0,
      y0i: 0.0,
      widthi: chart.settings.chart.width,
      heighti: chart.settings.chart.height,
    };
    super(params, chart, "topg");
    this.left = new LeftFrame(this, chart);
    this.right = new RightFrame(this, this.left, chart);
  }
  static get margins() {
    return { left: 0.0, right: 0.0, top: 0.0, bottom: 0.0 };
  }
}

interface IFrameFuncs {
  Parent(option: "frame" | "boundary"): void;
  Left(option: "frame" | "boundary"): void;
  Right(option: "frame" | "boundary"): void;
  RightMenu(option: "frame" | "boundary"): void;
  FundList(option: "frame" | "boundary"): void;
  ChartArea(option: "frame" | "boundary"): void;
  Chart(option: "frame" | "boundary"): void;
  XAxisControl(option: "frame" | "boundary"): void;
  YAxisControl(option: "frame" | "boundary"): void;
  Timeline(option: "frame" | "boundary"): void;
}

export interface IFrame<T> extends IFrameFuncs {
  frames: {
    parent: ParentFrame;
  };
  Frame(
    id: Exclude<keyof IFrame<T>, "frames" | "Frame" | "DrawBoundaries">
  ): void;
  DrawBoundaries(
    id: Exclude<keyof IFrame<T>, "frames" | "Frame" | "DrawBoundaries">
  ): void;
  height: number;
  width: number;
}

export type Frame<T> = T & IFrame<T>;

// TODO - replace dynamic Framer
export function intoFrame<T>(t: T) {
  return t as Frame<T>;
}

export function Framer<T>(this: Frame<T>, chart: BaseChart<any>) {
  this.frames = {
    parent: new ParentFrame(chart),
  };

  this.Parent = function (option: "frame" | "boundary") {
    if (option == "frame") this.frames.parent.Frame(this);
    else this.frames.parent.DrawBoundaries(this);
  };

  this.Left = function (option: "frame" | "boundary") {
    if (option == "frame") this.frames.parent.left.Frame(this);
    else this.frames.parent.left.DrawBoundaries(this);
  };

  this.Right = function (option: "frame" | "boundary") {
    if (option == "frame") this.frames.parent.right.Frame(this);
    else this.frames.parent.right.DrawBoundaries(this);
  };

  this.RightMenu = function (option: "frame" | "boundary") {
    if (option == "frame") this.frames.parent.right.rightmenu.Frame(this);
    else this.frames.parent.right.rightmenu.DrawBoundaries(this);
  };

  this.FundList = function (option: "frame" | "boundary") {
    if (option == "frame") this.frames.parent.right.fundlist.Frame(this);
    else this.frames.parent.right.fundlist.DrawBoundaries(this);
  };

  this.ChartArea = function (option: "frame" | "boundary") {
    if (option == "frame") this.frames.parent.left.chartArea.Frame(this);
    else this.frames.parent.left.chartArea.DrawBoundaries(this);
  };

  this.Chart = function (option: "frame" | "boundary") {
    if (option == "frame") this.frames.parent.left.chartArea.chart.Frame(this);
    else this.frames.parent.left.chartArea.chart.DrawBoundaries(this);
  };

  this.XAxisControl = function (option: "frame" | "boundary") {
    if (option == "frame") this.frames.parent.left.chartArea.xAxis.Frame(this);
    else this.frames.parent.left.chartArea.xAxis.DrawBoundaries(this);
  };

  this.YAxisControl = function (option: "frame" | "boundary") {
    if (option == "frame") this.frames.parent.left.chartArea.yAxis.Frame(this);
    else this.frames.parent.left.chartArea.yAxis.DrawBoundaries(this);
  };

  this.Timeline = function (option: "frame" | "boundary") {
    if (option == "frame") this.frames.parent.left.timeline.Frame(this);
    else this.frames.parent.left.timeline.DrawBoundaries(this);
  };

  this.Frame = function (id: keyof IFrameFuncs) {
    this[id]("frame");
  };

  this.DrawBoundaries = function (id: keyof IFrameFuncs) {
    this[id]("boundary");
  };

  return this;
}
