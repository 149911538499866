import _ from "lodash";
import { ParentSettings } from "./parent";

export interface IParamsSettings {
  x?: string | null;
  y?: string | null;
  color?: string | null;
}

// Overall Settings Object for Any Chart - Inherited by Specific Chart Settings Objects
abstract class ParamsSettingsParent extends ParentSettings<IParamsSettings> {
  x: null | string = null;
  y: null | string = null;
  color: null | string = null;

  constructor(settings: any) {
    super(settings, [], "Params", {
      x: "string_if_defined",
      y: "string_if_defined",
      color: "string_if_defined",
    });
  }

  populate() {
    const providedKeys = Object.keys(this.settings);

    const availableKeys = Object.keys(this).filter(key => {
      if (key !== "validation" && key !== "__name__" && key !== "settings") {
        return true;
      }
      return false;
    });

    for (const key of providedKeys) {
      // Make Sure Key is Valid
      if (!availableKeys.includes(key))
        throw new Error(`Invalid Key ${key} for ${this.__name__} Settings`);
      (this as any)[key] = (this.settings as any)[key];
    }
    return this;
  }

  validate(model: any) {
    super.validate(model);

    const availableKeys = (Object.keys(this) as (string & keyof this)[]).filter(
      key => {
        if (key !== "validation" && key !== "__name__" && key !== "settings") {
          if (this[key] instanceof Function) return false;
          return true;
        }
        return false;
      }
    );

    for (const key of availableKeys) {
      // If Present - Make Sure Model Has Data
      if (this[key]) {
        const value = this[key];
        if (typeof value !== "string") {
          throw Error(`Value of key ${key} is not a string`);
        }
        let sameSize =
          (key == "z" && value.toLowerCase() == "same") ||
          (key == "color" && value.toLowerCase() == "same");
        if (!sameSize) {
          if (
            !_.includes(model.numeric, value) &&
            !_.includes(model.categoric, value)
          ) {
            throw new Error(
              "Invalid Parameter " +
                value +
                " Specified for " +
                key +
                "Axis... Parameter Does Not Exist in Data"
            );
          }
          if (value.toLowerCase() == "date") {
            throw new Error("Cannot Specify Date as Axis Parameter");
          }
        }
      }
    }
  }
}

export interface ITransitionParamsSettings extends IParamsSettings {
  z?: string | null;
}

export class TransitionParamsSettings extends ParamsSettingsParent {
  z: null | string = null;

  constructor(settings: ITransitionParamsSettings) {
    super(settings);
    this.validation["z"] = "string_if_defined";
  }

  override validate(model: any) {
    super.validate(model);
    // Validate That Parameters Specified Are Specifically Valid
    if (this.y && !_.includes(model.numeric, this.y)) {
      throw new Error("Y Axis Parameter for Bubble Chart Must be Numeric");
    }
  }
}

export interface IBubbleParamsSettings extends IParamsSettings {
  z?: string | null;
}

export class BubbleParamsSettings extends ParamsSettingsParent {
  z: null | string = null;

  constructor(settings: IBubbleParamsSettings) {
    super(settings);
    this.validation["z"] = "string_if_defined";
  }

  override validate(model: any) {
    super.validate(model);
    // Validate That Parameters Specified Are Specifically Valid
    if (this.x && !_.includes(model.numeric, this.x)) {
      throw new Error("X Axis Parameter for Bubble Chart Must be Numeric");
    }
    if (this.y && !_.includes(model.numeric, this.y)) {
      throw new Error("Y Axis Parameter for Bubble Chart Must be Numeric");
    }
  }
}

export interface IBarParamsSettings extends IParamsSettings {}

export class BarParamsSettings extends ParamsSettingsParent {
  constructor(settings: IBarParamsSettings) {
    super(settings);
  }

  override validate(model: any) {
    super.validate(model);
    // Validate That Parameters Specified Are Specifically Valid
    if (this.y && !_.includes(model.numeric, this.y)) {
      throw new Error("Y Axis Parameter for Bubble Chart Must be Numeric");
    }
  }
}
