import * as d3 from "d3";
import _ from "lodash";
import { Constants } from "../constants";
import { DataPoint } from "../models/point";
import Utilities from "../utilities/utilities";
import type { BaseChart } from "../base_chart";
import type { DropdownScroller } from "./dropdown-scroll";

export interface IFundList {
  __name__: string;
  chart: BaseChart<any>;
  view: {
    slider?: d3.Selection<SVGRectElement, any, HTMLElement, any>;
    line?: d3.Selection<SVGLineElement, any, HTMLElement, any>;
    scrollBg?: d3.Selection<SVGRectElement, any, HTMLElement, any>;
    selectRects?: d3.Selection<SVGRectElement, unknown, SVGGElement, any>;
    names?: d3.Selection<SVGTextElement, unknown, SVGGElement, any>;
    items?: d3.Selection<d3.BaseType, unknown, SVGGElement, any>;
    itemsContainer?: d3.Selection<SVGGElement, any, HTMLElement, any>;
    rects?: d3.Selection<SVGRectElement, unknown, SVGGElement, any>;
  };
  data: any[];
  focus(datum: any): void;
  unfocus(datum: any): void;
  hide(datum: any): void;
  show(datum: any): void;
  selectPoint(datum: any): void;
  deselectPoint(datum: any): void;
  drag(event: any): void;
  drawScrollBar(range: any): void;
  clicked(event: PointerEvent, d: DataPoint): void;
  toggleFocus(event: PointerEvent, d: DataPoint): void;
  hideShow(event: PointerEvent, d: DataPoint): void;
  color(): void;
  renderItems(): void;
  initialize(data: any): void;
  width: number;
  height: number;
}

export type FundList<T> = Omit<T, "data"> & IFundList;

export function intoFundList<T>(t: T) {
  return t as FundList<T>;
}

export const FundList = function (
  this: FundList<
    DropdownScroller<d3.Selection<SVGGElement, any, HTMLElement, any>>
  >,
  chart: BaseChart<any>
) {
  this.__name__ == "FundList";
  this.chart = chart;
  this.attr("class", "fund-list");

  this.view = {};
  this.data = [];

  this.startIndex_ = 0;
  this.maxItems = 0; // Need to Calculate On Initialization

  Object.defineProperty(this, "endIndex", {
    configurable: true,
    get: function endIndex() {
      return this.startIndex + this.maxItems;
    },
  });

  Object.defineProperty(this, "visible", {
    configurable: true,
    get: function visible() {
      if (!this.data) return [];
      else return this.data.slice(this.startIndex, this.endIndex);
    },
  });

  Object.defineProperty(this, "startIndex", {
    configurable: true,
    get: function startIndex() {
      return this.startIndex_;
    },
    set: function startIndex(value) {
      this.startIndex_ = value;
      this.renderItems();
    },
  });

  this.focus = function (datum: any) {
    if (this.chart.focused != datum.Name && !this.chart.hidden[datum.Name]) {
      this.chart.focus(datum, { from: this });
      this.color();
    }
  };

  this.unfocus = function (datum: any) {
    if (this.chart.focused == datum.Name) {
      this.chart.unfocus(datum, { from: this });
      this.color();
    }
  };

  this.hide = function (datum: any) {
    if (!this.chart.hidden[datum.Name]) {
      this.chart.hide(datum, { from: this });
      this.color();
    }
  };

  this.show = function (datum: any) {
    if (this.chart.hidden[datum.Name]) {
      this.chart.show(datum, { from: this });
      this.color();
    }
  };

  this.selectPoint = function (datum: any) {
    if (!this.chart.selected[datum.Name]) {
      this.chart.selectPoint(datum, { from: this });
      this.color();
    }
  };
  // Called Internally - Underscore Denotes Internal
  this.deselectPoint = function (datum: any) {
    if (this.chart.selected[datum.Name]) {
      let node = this.selectAll(".fund-list-item").filter(function (d: any) {
        return d.Name == datum.Name;
      });
      node.select("text").classed("selected", false);
      this.chart.deselectPoint(datum, { from: this });

      this.color();
    }
  };

  // Called When Scrollbar Scrolled
  this.drag = function (event: any) {
    const scrollAreaElement = document.getElementById(this.chart.ids!.fundlist);
    if (!scrollAreaElement) {
      console.error("Scroll area element not found!");
      return;
    }

    // Use d3.pointer to get the mouse position relative to the scroll area
    const pointer = d3.pointer(event, scrollAreaElement);
    const mouseY = pointer[1];

    // let mouseY = d3.mouse($("#" + this.chart.ids.fundlist)[0])[1]
    let posY = this.scrollScale!.invert(mouseY);
    this.view.slider!.attr("y", posY);

    let index = this.scrollScaleIdentifier!(mouseY);
    let point = this.data[index];

    if (!point) throw new Error("Scroll Error");
    this.startIndex = index; // Rerenders in Setter
  };

  // Draw Scroll Bar After Initial Render so Dimensions Set
  this.drawScrollBar = function (range: any) {
    let self = this;

    this.view.scrollBg = this.append("rect")
      .attr(
        "x",
        this.width -
          Constants.Scroll.margins.left -
          0.5 * Constants.Scroll.width -
          2
      )
      .attr("y", 0)
      .attr("width", this.width)
      .attr("height", this.height)
      .attr("class", "scroll-bg");

    // Cut Off Excess At End So Scroll Domain Correct
    // Not Currently Used - Need to Reimplement
    let end = Math.max(this.data.length - this.maxItems + 1, 0);

    this.domain = [];
    for (let i = 0; i < end; i++) {
      this.domain.push(i);
    }

    if (this.domain.length > 0) {
      this.view.line = this.append("line")
        .attr("y1", range[0])
        .attr("y2", range[1])
        .attr(
          "x1",
          this.width -
            Constants.Scroll.margins.left -
            0.5 * Constants.Scroll.width
        )
        .attr(
          "x2",
          this.width -
            Constants.Scroll.margins.left -
            0.5 * Constants.Scroll.width
        )
        .attr("class", "scroll-line");

      this.view.slider = this.append("rect")
        .attr("class", "scroll-slider")
        .attr("y", range[0])
        .attr("height", Constants.Scroll.slider.height)
        .attr("width", Constants.Scroll.width)
        .attr("rx", Constants.Scroll.slider.rx)
        .attr("ry", Constants.Scroll.slider.ry)
        .attr("x", function () {
          return (
            self.width - Constants.Scroll.margins.left - Constants.Scroll.width
          );
        })
        .call(d3.drag().on("drag", _.bind(self.drag, self)) as any);

      // Scroll Scale Maps Drag Point to Clamped Point in Range, Identifier Maps Drag Point to Specific Name
      this.scrollScale = d3
        .scaleLinear()
        .domain(range)
        .range(range)
        .clamp(true);
      this.scrollScaleIdentifier = d3
        .scaleQuantize()
        .domain(range)
        .range(this.domain);
    }
  };

  this.clicked = function (event: PointerEvent, d: DataPoint) {
    let point = _.find(this.data, { Name: d.Name });
    if (!point) throw new Error("Point With Name", d.Name);

    if (this.chart.selected[point.Name]) {
      this.deselectPoint(point);
    } else {
      this.selectPoint(point);
    }
  };

  this.toggleFocus = function (event: PointerEvent, d: DataPoint) {
    let self = this;

    let point = _.find(this.data, { Name: d.Name });
    if (!point) throw new Error("Point With Name", d.Name);

    if (this.chart.focused == point.Name) {
      this.unfocus(point);
    } else {
      this.focus(point);
      let others = _.filter(this.data, function (point) {
        return point.Name != d.Name;
      });
      _.each(others, function (other) {
        self.unfocus(other);
      });
    }
  };

  this.hideShow = function (event: PointerEvent, d: DataPoint) {
    let point = _.find(this.data, { Name: d.Name });
    if (!point) throw new Error("Point With Name", d.Name);

    if (this.chart.hidden[point.Name]) {
      this.show(point);
    } else {
      this.hide(point);
    }
  };

  this.color = function () {
    let self = this;

    this.view
      .selectRects!.attr("fill", function (d: any, i: any) {
        if (self.chart.hidden[d.Name]) {
          return "#FFF";
        }
        let color = self.chart.axes.color.scale(d);
        color = Utilities.Coloring.universal_rgb_convert(color, 0.4);
        return color;
      })
      .attr("stroke", function (d: any, i: any) {
        let color = self.chart.axes.color.scale(d);
        color = Utilities.Coloring.universal_rgb_convert(color, 1.0);
        return color;
      });

    this.view
      .names!.classed("focused", function (d: any, i: any) {
        return self.chart.focused == d.Name;
      })
      .classed("selected", function (d: any, i: any) {
        return self.chart.selected[d.Name];
      })
      .classed("hidden_item", function (d: any, i: any) {
        return self.chart.hidden[d.Name];
      })
      .attr("fill", function (d: any, i: any) {
        if (self.chart.selected[d.Name]) {
          let color = self.chart.axes.color.scale(d);
          return color;
        }
        return Constants.FundList.item.textColor;
      });
  };
  // Draws Only the Elements Within the Range We Desire
  // To Do: See If There is Easier Way of Including Index Without Manually Applying to Data Points
  // Manually Applying Data Points Could Cause Issues
  this.renderItems = function () {
    let self = this;

    this.selectAll(".fund-list-item").remove();
    this.view.items = this.view
      .itemsContainer!.selectAll(".fund-list-item")
      .data(this.visible);

    const enter = this.view.items
      .enter()
      .append("g")
      .attr("class", "fund-list-item")
      .attr("transform", function (d: any, i: any) {
        return (
          "translate(" +
          String(0.0) +
          "," +
          String(
            (Constants.FundList.item.height + Constants.FundList.spacing) * i
          ) +
          ")"
        );
      });

    this.view.rects = enter
      .append("rect")
      .attr("class", "fund-list-rect")
      .attr("height", Constants.FundList.item.height)
      .attr("x", 0.0)
      .attr("y", 0.0)
      .attr(
        "width",
        this.width -
          Constants.Scroll.margins.left -
          Constants.Scroll.width -
          Constants.Scroll.margins.right
      ); // Width from Right Frame Setting

    this.view.selectRects = enter
      .append("rect")
      .attr("class", "fund-list-select-rect")
      .attr(
        "height",
        Constants.FundList.item.height - 2.0 * Constants.FundList.item.padding
      )
      .attr(
        "width",
        Constants.FundList.item.height - 2.0 * Constants.FundList.item.padding
      )
      .attr("x", Constants.FundList.item.padding)
      .attr("y", Constants.FundList.item.padding);

    this.view.names = enter
      .append("text")
      .attr("class", "fund-list-name")
      .attr("alignment-baseline", "middle")
      .attr(
        "x",
        Constants.FundList.item.padding +
          (Constants.FundList.item.height -
            2.0 * Constants.FundList.item.padding) +
          5.0
      )
      .attr("y", 0.5 * Constants.FundList.item.height);

    this.view.names.text(function (d: any) {
      return d.Name;
    });

    this.color();

    this.view.rects.on("click", _.bind(this.clicked, this));
    this.view.names.on("click", _.bind(this.clicked, this));
    this.view.selectRects.on("click", _.bind(this.hideShow, this));

    this.view.names.on("mouseover", _.bind(this.toggleFocus, this));
    this.view.names.on("mouseout", _.bind(this.toggleFocus, this));
    this.view.rects.on("mouseover", _.bind(this.toggleFocus, this));
    this.view.rects.on("mouseout", _.bind(this.toggleFocus, this));

    this.view.items.exit().remove();
  };

  // Like Bubbles, Bars, etc. We Are Only Looking for Additional Items to Add Here - In Future, Want to Remove Managers Not on Date
  this.initialize = function (data: any) {
    // Calculate Max Items Based on Available Height
    this.maxItems = Math.floor(
      this.height /
        (Constants.FundList.item.height + Constants.FundList.spacing)
    );

    this.data = data;
    this.data = this.data.sort(function (a: any, b: any) {
      let textA = a.Name.toUpperCase();
      let textB = b.Name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    this.view.itemsContainer = this.append("g");
    this.renderItems();

    //let range = [box.y + 26.0, box.y + box.height + 10.0]
    let range = [
      0.0,
      this.chart.fundList.height - Constants.Scroll.slider.height,
    ];
    this.drawScrollBar(range);
  };

  return this;
};
