import { ParentSettings } from "./parent";
import { BubbleParamsSettings, IBubbleParamsSettings } from "./params";
import { ASSERTIONS } from "./assertions";
import _ from "lodash";

export interface GroupArgs {
  color?: string | null;
  name?: string | null;
  data?: string[];
}

export class Group {
  color: string | null;
  name: string | null;
  data: string[];

  constructor(args: GroupArgs) {
    this.color = args.color ?? null;
    this.name = args.name ?? null;
    this.data = args.data ?? [];
  }

  // TOTO type model
  validate(model: any) {
    for (const name of this.data) {
      if (!_.includes(model.names, name)) {
        throw new Error("Invalid Name Specified for Group " + this.name);
      }
    }
  }

  // TODO type point
  contains(point: any) {
    return this.data.includes(point.Name);
  }
}

export interface IChartSettings {
  height: number;
  contextMenu?: boolean;
  width: number;
  gridlines?: boolean;
  playDuration?: number; // Time in ms
  animationDuration?: number; // Time in ms
  hover?: unknown;
  clicked?: unknown;
  unhover?: unknown;
  unclicked?: unknown;
  groups?: GroupArgs[];
  params?: IBubbleParamsSettings;
  showBoundaries?: boolean;
}

export class ChartSettings extends ParentSettings<IChartSettings> {
  public height: number;
  public contextMenu: boolean;
  public width: number;
  public gridlines: boolean;
  public playDuration: number; // Time in ms
  public animationDuration: number; // Time in ms
  public hover: unknown;
  public clicked: unknown;
  public unhover: unknown;
  public unclicked: unknown;
  public groups: Group[];
  public params?: BubbleParamsSettings;
  public showBoundaries: boolean;

  constructor(settings?: IChartSettings) {
    super(settings, ["params", "highlight"], undefined, {
      playDuration: "numeric",
      animationDuration: "numeric",
      gridlines: "bool",
      showBoundaries: "bool",
      height: "numeric",
      width: "numeric",
      contextMenu: "bool",
      hover: "function",
      clicked: "function",
      unhover: "function",
      unclicked: "function",
      groups: "array",
    });

    this.height = 520;
    this.contextMenu = true;
    this.width = 950;
    this.gridlines = true;
    this.playDuration = 10000;
    this.animationDuration = 250;

    this.hover = null;
    this.clicked = null;
    this.unhover = null;
    this.unclicked = null;

    this.groups = [];

    // Show Boundaries of Chart Area
    this.showBoundaries = false;
  }

  override validate(model: any) {
    super.validate(model);
    for (const group of this.groups) {
      group.validate(model);
    }
    return;
  }

  override populate() {
    super.populate("groups");

    const { groups } = this.settings;
    if (groups) {
      // Need to Validate Groups Before Trying to Populate
      ASSERTIONS.array("groups", groups); // No Options - Blackbox
      for (const group of groups) {
        const groupObj = new Group(group);
        this.groups.push(groupObj);
      }
    }

    this.params = new BubbleParamsSettings(this.settings.params || {});
    this.params.populate();
    return this;
  }
}
