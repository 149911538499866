import * as d3 from "d3";
import _ from "lodash";
import { Constants } from "../constants";
import Coordinate from "../utilities/coordinate";
import Utilities from "../utilities/utilities";

export const LabelColor = function (this: typeof d3.selection.prototype) {
  // To Do: Use Fill Alpha Specified by Settings Instead of CSS
  // For Now - Stroke and Fill Same Colors Because No Alpha Applied - Alpha Applied in CSS
  Object.defineProperty(this, "fillColor", {
    configurable: true,
    get: function fillColor() {
      let color = this.chart.axes.color.scale(this.datum());
      color = Utilities.Coloring.universal_rgb_convert(color, 1.0);
      return color;
    },
  });
  Object.defineProperty(this, "strokeColor", {
    configurable: true,
    get: function strokeColor() {
      // Alpha Specified by CSS
      let color = this.chart.axes.color.scale(this.datum());
      color = Utilities.Coloring.universal_rgb_convert(color, 1.0);
      return color;
    },
  });
};

export const LabelDimensions = function (this: typeof d3.selection.prototype) {
  Object.defineProperty(this, "width", {
    configurable: true,
    get: function width() {
      if (!this.center) throw new Error("Center Coordinate Not Defined");
      return Math.abs(this.coordinates.x2 - this.coordinates.x1);
    },
  });
  Object.defineProperty(this, "height", {
    configurable: true,
    get: function height() {
      if (!this.center) throw new Error("Center Coordinate Not Defined");
      return Math.abs(this.coordinates.y2 - this.coordinates.y1);
    },
  });
};

export const LabelPosition = function (this: typeof d3.selection.prototype) {
  Object.defineProperty(this, "coordinates", {
    configurable: true,
    get: function coordinates() {
      if (!this.center || !this.text)
        throw new Error(
          "Center Coordinate and Text Must be Defined Before Obtaining Coordinates"
        );
      let bbox = this.text.node().getBBox();

      let coordinates = {
        y1: this.center.y - 0.5 * bbox.height - Constants.Chart.labels.padding,
        x1: this.center.x - 0.5 * bbox.width - Constants.Chart.labels.padding,
        y2: this.center.y + 0.5 * bbox.height + Constants.Chart.labels.padding,
        x2: this.center.x + 0.5 * bbox.width + Constants.Chart.labels.padding,
        topleft: { x: 0, y: 0 },
        topright: { x: 0, y: 0 },
        bottomleft: { x: 0, y: 0 },
        bottomright: { x: 0, y: 0 },
      };

      coordinates.topleft = { x: coordinates.x1, y: coordinates.y1 };
      coordinates.topright = { x: coordinates.x2, y: coordinates.y1 };
      coordinates.bottomleft = { x: coordinates.x1, y: coordinates.y2 };
      coordinates.bottomright = { x: coordinates.x2, y: coordinates.y2 };
      return coordinates;
    },
  });
};

// Label States
// (1) Visible - The Label is Showing Regardless of Whether it is Showing from Being Focused or Selected
// (2) Selected - Label is Showing Due to Selection of Marker
// (3) Focused - Label is Showing Due to Focus of Marker (Usually at a Given Alpha)
export const MarkerLabel: any = function (
  this: typeof d3.selection.prototype,
  group: any,
  marker: any,
  markers: any,
  chart: any
) {
  this.chart = chart;
  this.marker = marker;
  this.markers = markers;
  this.group = group;

  LabelColor.bind(this)();
  LabelDimensions.bind(this)();
  LabelPosition.bind(this)();

  this.visible = false;
  this.attr("class", "element-label");

  this.hide = function () {
    this.text.remove();
    this.rect.remove();
    this.text = null;
    this.rect = null;
  };
  // To Do: Check if Auto Position Already Set and Only Readjust if this.chart.settings.labels.autoAdjust is True
  this.focus = function () {
    // If Already Visible - Just Recolor
    if (this.visible) {
      this.color();
      return;
    }

    this.visible = true;
    this.draw();
  };
  this.unfocus = function () {
    if (this.group.selected) {
      this.color();
    } else if (this.visible) {
      this.visible = false;
      this.hide(); // Colors With Updated State
    }
  };
  // To Do: Check if Auto Position Already Set and Only Readjust if this.chart.settings.labels.autoAdjust is True
  this.select_ = function () {
    this.visible = true;
    this.draw();
  };
  // Called from Chart Object Itself - Not Called Internally
  this.deselect = function () {
    this.hide();
    this.visible = false;
  };

  this.color = function () {
    if (!this.rect || !this.text)
      throw new Error("Cannot Color Without Drawing Rect and Text Components");

    this.classed("focused", function (d: any) {
      return self.group.focused;
    });
    this.classed("selected", function (d: any) {
      return self.group.selected;
    });
    this.rect.attr("fill", this.fillColor);
    this.rect.attr("stroke", this.strokeColor);
  };

  this.create = function () {
    let self = this;
    this.center_ = null; // Have to Clear Center So Label Position is Relative to New Position/Item

    this.text = this.append("text")
      .attr("alignment-baseline", "middle")
      .text(function (d: any) {
        let string = self.datum().Name;
        let formatted = Utilities.Charting.generateLabel(string);
        return formatted;
      });

    this.rect = this.append("rect")
      .attr("width", this.width)
      .attr("height", this.height)
      .attr("rx", 2)
      .attr("ry", 2);
  };
  // Called on Focus or Select - Creates the Label Elements and Draws
  // Do Not Call This on Animation or Draw of Bubbles - It Will Redraw -
  this.draw = function () {
    if (!this.text || !this.rect) this.create();

    // Move Text Center to Coordinate
    this.text.attr(
      "transform",
      "translate(" +
        String(this.coordinates.x1 + Constants.Chart.labels.padding) +
        "," +
        String(this.coordinates.y1 + 0.5 * this.height) +
        ")"
    );
    this.rect.attr(
      "transform",
      "translate(" +
        String(this.coordinates.x1) +
        "," +
        String(this.coordinates.y1) +
        ")"
    );

    this.color();
  };
  // Adjusts Position as Bubbles Move
  this.animate = function () {
    if (!this.text || !this.rect) this.create();

    // Move Text Center to Coordinate
    let self = this;
    self.text
      .transition()
      .duration(self.chart.settings.chart.animationDuration)
      .ease(d3.easeQuadOut)
      .attr(
        "transform",
        "translate(" +
          String(self.coordinates.x1 + Constants.Chart.labels.padding) +
          "," +
          String(self.coordinates.y1 + 0.5 * self.height) +
          ")"
      );

    self.rect
      .transition()
      .duration(self.chart.settings.chart.animationDuration)
      .ease(d3.easeQuadOut)
      .attr(
        "transform",
        "translate(" +
          String(self.coordinates.x1) +
          "," +
          String(self.coordinates.y1) +
          ")"
      );

    self.color();
  };
  // Event Handlers
  let self = this;
  this.on("mouseover", function () {
    self.chart.focus(self.datum());
  });
  this.on("mouseout", function () {
    self.chart.unfocus(self.datum());
  });
  this.on("clicked", function (this: any) {
    debugger;
    if (self.group.selected) {
      self.chart.deselectPoint(this.datum());
    } else {
      self.chart.selectPoint(this.datum());
    }
  });

  this.dragged = function (event: DragEvent) {
    this.center = new Coordinate(event.x, event.y); // Will Animate in Setter
    this.group.point(); // Have to Call Group Point To Since It Adjusts the End Point to Outside of Label Rect
  };
  this.call(d3.drag().on("drag", _.bind(self.dragged, self)));
  return this;
};

export const BubbleLabel = function (
  this: typeof d3.selection.prototype,
  group: any,
  marker: any,
  markers: any,
  chart: any
) {
  MarkerLabel.bind(this)(group, marker, markers, chart);

  this.defaultLabelOffset = { x: 60.0, y: -50.0 }; // Label Offset from Center of Bar - Used as Default if Auto Positioning Turned Off
  if (this.center_ === undefined) this.center_ = null;

  Object.defineProperty(this, "center", {
    configurable: true,
    get: function center() {
      if (!this.text)
        throw new Error("Text Must be Added Before Centering Label");

      // Have to Dynamically Adjust Default Center
      if (!this.center_) {
        this.defaultCenter = new Coordinate(
          this.marker.center.x + this.defaultLabelOffset.x,
          this.marker.center.y + this.defaultLabelOffset.y
        );
        this.center_ = this.defaultCenter;
      }
      return this.center_;
    },
    set: function center(value) {
      this.center_ = value;
      this.draw();
    },
  });

  return this;
};

export const BarLabel = function (
  this: typeof d3.selection.prototype,
  group: any,
  marker: any,
  markers: any,
  chart: any
) {
  MarkerLabel.bind(this)(group, marker, markers, chart);

  this.defaultLabelOffset = { x: 50.0, y: 40.0 }; // Label Offset from Center of Bar - Used as Default if Auto Positioning Turned Off
  if (this.center_ === undefined) this.center_ = null;

  Object.defineProperty(this, "center", {
    configurable: true,
    get: function center() {
      if (!this.text)
        throw new Error("Text Must be Added Before Centering Label");

      // Have to Dynamically Adjust Default Center
      if (!this.center_) {
        if (this.marker.negative) {
          this.center_ = new Coordinate(
            this.marker.center.x + this.defaultLabelOffset.x,
            this.marker.y2 + this.defaultLabelOffset.y
          );
        } else {
          this.center_ = new Coordinate(
            this.marker.center.x + this.defaultLabelOffset.x,
            this.marker.y1 - this.defaultLabelOffset.y
          );
        }
      }
      return this.center_;
    },
    set: function center(value) {
      this.center_ = value;
      this.draw();
    },
  });
  return this;
};

// Not Allowing Redefinition of Center Property When Toggling Label Types
export const BubbleBarLabel = function (
  this: typeof d3.selection.prototype,
  group: any,
  marker: any,
  markers: any,
  chart: any
) {
  this.toggle = function () {
    if (chart.activeChartType == "BarChart")
      BarLabel.bind(this)(group, marker, markers, chart);
    else BubbleLabel.bind(this)(group, marker, markers, chart);
  };
  this.toggle();
  return this;
};
