import * as d3 from "d3";
import { ContextMenu } from "../elements/context-menu";
import InterpolatedPoint from "../models/interpolated";
import Coordinate from "../utilities/coordinate";
import { BarCrossHairPoints, BubbleCrossHairPoints } from "./crosshairs";
import { BarDimensions, BubbleDimensions } from "./dimensions";
import { BarPosition, BubblePosition } from "./positions";
import { BarProperties, BubbleProperties } from "./properties";

// This Is What Will be Inherite by BubbleBar
export const MarkerResponse = function (this: typeof d3.selection.prototype) {
  let self = this;
  this.on("mouseover", function () {
    if (!self.hidden) {
      self.chart.focus(self.datum());
    }
  });
  this.on("mouseout", function () {
    if (!self.hidden) {
      self.chart.unfocus(self.datum());
    }
  });
  this.on("click", function () {
    if (self.group.selected) {
      self.chart.deselectPoint(self.datum());
    } else {
      self.chart.selectPoint(self.datum());
    }
  });
  this.on("contextmenu", ContextMenu(this.menu));
};

// Inherited by Marker Label, Marker Connector, Bubble, Bar and Bubble Bar
export const Marker = function (
  this: typeof d3.selection.prototype,
  group: any,
  markers: any,
  chart: any
) {
  this.chart = chart;
  this.group = group;
  this.markers = markers;

  Object.defineProperty(this, "center", {
    configurable: true,
    get: function center() {
      return new Coordinate(this.cX, this.cY);
    },
  });

  let self = this;
  this.menu = [
    {
      title: "Hide",
      action: function (elm: any, d: any, i: any) {
        self.chart.hide(self.datum());
      },
      disabled: false,
    },
  ];

  this.focus = function () {
    this.color();
    this.markers.showCrossHairs(this);
  };

  this.unfocus = function () {
    this.color();
    this.markers.hideCrossHairs(this);
  };
  this.select_ = function () {
    this.color();
  };
  this.deselect = function () {
    this.color();
  };

  MarkerResponse.bind(this)();
  return this;
};

export const Bar = function (
  this: typeof d3.selection.prototype,
  group: any,
  bars: any,
  chart: any
) {
  this.__name__ = "Bar";
  this.attr("class", "bar");
  this.Name = this.datum().Name;

  Marker.bind(this)(group, bars, chart);

  BarCrossHairPoints.bind(this)();
  BarPosition.bind(this)();
  BarDimensions.bind(this)();
  BarProperties.bind(this)();

  this.animate = function (datum: any) {
    if (datum) this.datum(datum);

    if (!this.height) {
      // don't draw point if nothing to draw, I think that null points may have been cast to 0 prior
      return;
    }

    this.transition()
      .duration(250)
      .ease(d3.easeQuadOut)
      .attr("width", this.width)
      .attr("height", this.height)
      .attr("x", this.x)
      .attr("y", this.y);

    this.color();
  };

  this.draw = function (datum: any) {
    if (datum) this.datum(datum);

    if (!this.height) {
      // don't draw point if nothing to draw, I think that null points may have been cast to 0 prior
      return;
    }

    this.attr("width", this.width)
      .attr("height", this.height)
      .attr("x", this.x)
      .attr("y", this.y);
    this.color();
  };

  return this;
};

export const Bubble = function (
  this: typeof d3.selection.prototype,
  group: any,
  bubbles: any,
  chart: any
) {
  this.__name__ = "Bubble";
  this.attr("class", "dot");
  this.Name = this.datum().Name;

  Marker.bind(this)(group, bubbles, chart);

  BubbleCrossHairPoints.bind(this)();
  BubblePosition.bind(this)();
  BubbleDimensions.bind(this)();
  BubbleProperties.bind(this)();

  this.animate = function (datum: any) {
    if (datum) this.datum(datum);

    let self = this;
    this.transition()
      .duration(250)
      .ease(d3.easeQuadOut)
      .attr(
        "transform",
        "translate(" + String(this.center.x) + "," + String(this.center.y) + ")"
      )
      .attr("r", self.r);

    this.color();
  };

  this.draw = function (datum: any) {
    if (datum) this.datum(datum); // If Data is Not Provided - Uses Existing Datum
    let self = this;
    this.attr(
      "transform",
      "translate(" + String(this.center.x) + "," + String(this.center.y) + ")"
    );
    this.attr("r", self.r);
    this.color();
  };
  return this;
};

export const BubbleBarOverride = function (
  this: typeof d3.selection.prototype
) {
  this.animate = function (datum: any) {
    if (datum) this.datum(datum);

    if (!this.height) {
      // don't draw point if nothing to draw, I think that null points may have been cast to 0 prior
      return;
    }

    this.transition()
      .duration(250)
      .ease(d3.easeQuadOut)
      .attr("width", this.width)
      .attr("height", this.height)
      .attr("x", this.x)
      .attr("y", this.y)
      .attr("rx", this.r)
      .attr("ry", this.r);

    this.color();
  };

  this.draw = function (datum: InterpolatedPoint) {
    if (datum) this.datum(datum);

    if (!this.height) {
      // don't draw point if nothing to draw
      return;
    }

    this.attr("width", this.width)
      .attr("height", this.height)
      .attr("x", this.x)
      .attr("y", this.y)
      .attr("rx", this.r)
      .attr("ry", this.r);

    this.color();
  };
};

export const BubbleBar = function (
  this: typeof d3.selection.prototype,
  group: any,
  bubbleBars: any,
  chart: any
) {
  this.__name__ = "Bubble";
  Marker.bind(this)(group, bubbleBars, chart);

  this.toggle = function () {
    switch (this.chart.activeChartType) {
      case "BubbleChart":
        Bubble.bind(this)(group, bubbleBars, chart);
        BubbleBarOverride.bind(this)();
        //this.BubbleBehavior(this.group, this.bubbleBars, this.chart)
        break;
      case "BarChart":
        Bar.bind(this)(group, bubbleBars, chart);
        BubbleBarOverride.bind(this)();
        //this.BarBehavior(this.group, this.bubbleBars, this.chart)
        break;
    }
  };

  this.toggle();
  return this;
};

export const NegativityLabel = function (
  this: typeof d3.selection.prototype,
  group: any,
  bubbles: any,
  chart: any
) {
  const width = 12;
  const height = 2;

  Marker.bind(this)(group, bubbles, chart);
  this.attr("class", "negativity-label");
  this.attr("width", width);
  this.attr("height", height);
  this.attr("rx", 1);
  this.attr("ry", 1);
  BubblePosition.bind(this)();
  BubbleDimensions.bind(this)();
  BubbleProperties.bind(this)();

  this.updateDisplay = function () {
    const isBubbleChart = this.chart.activeChartType === "BubbleChart";
    const z =
      this.chart.params.z === "same" ? 0 : this.datum()[this.chart.params.z];
    const result = isBubbleChart && z < 0;
    this.attr("style", `display: ${result ? "inherit" : "none"}`);
    return result;
  };

  this.animate = function (datum: any) {
    if (datum) this.datum(datum);
    if (this.updateDisplay()) {
      let self = this;
      this.attr(
        "transform",
        "translate(" +
          String(this.center.x - width / 2) +
          "," +
          String(this.center.y - height / 2) +
          ")"
      );
      this.color();
    }
  };

  this.draw = function (datum: any) {
    if (datum) this.datum(datum);
    if (this.updateDisplay()) {
      let self = this;
      this.attr(
        "transform",
        "translate(" +
          String(this.center.x - width / 2) +
          "," +
          String(this.center.y - height / 2) +
          ")"
      );
      this.color();
    }
  };

  return this;
};
