/* eslint-disable react/jsx-props-no-spreading */
import * as types from "_graphql-types-frontend/graphql";
import { Select } from "antd";
import { useCallback, useContext } from "react";
import { useCurrentUser } from "../../../../utils/hooks";
import { TemplateContext } from "../../Context";
import { FieldStamp } from "../FieldAuditStamp";
import { ParentContext, useTemplateController } from "../FieldContext";
import { InputFieldProps } from "./InputFieldBase";

const { Option } = Select;

function displayLabel(
  parent: string,
  displayName: string | null | undefined,
  questionText: string | null | undefined
) {
  if (parent !== "checklist") {
    // split the questionText by new line and replace endline characters with <br />
    return (
      questionText &&
      questionText.split("\n").map(line => (
        <span key={line}>
          {line}
          <br />
        </span>
      ))
    );
  }
  return displayName;
}

export default function BooleanDropdownFieldInput({
  templateKey,
  metadata,
  templateOverride,
  formLookupKey,
  NotApplicableWrapper,
}: InputFieldProps): JSX.Element {
  const {
    flags: { isRockCreek },
  } = useCurrentUser();

  const displayName = templateOverride?.displayName || metadata.displayName;
  const questionText = templateOverride?.questionText || metadata.questionText;

  const { readFieldFromCache } = useContext(TemplateContext);
  const {
    field: { onChange, ...inputProps },
  } = useTemplateController({
    name: formLookupKey,
  });

  const { field: managerData } = readFieldFromCache(
    templateKey,
    types.FieldState.Mgr
  );

  const handleChange = useCallback(
    (value: boolean | null) => onChange(value),
    []
  );

  const parent = useContext(ParentContext);

  return (
    <>
      <div
        data-cy="template__textarea"
        data-test-id="template__textarea"
        className={"main-input__info"}
      >
        <div className="main-input__sublabel">
          {displayLabel(parent, displayName, questionText)}
        </div>
      </div>
      <NotApplicableWrapper {...{ templateKey, metadata }}>
        <>
          <Select
            {...inputProps}
            defaultValue={inputProps.value}
            style={{ marginRight: "20px", maxWidth: "100px" }}
            className="main-select"
            dropdownClassName="main-select__menu"
            onChange={handleChange}
            value={
              typeof inputProps.value === "boolean" ? inputProps.value : null
            }
          >
            {["", "Yes", "No"].map(option => (
              <Option
                key={option}
                value={option === "" ? null : option === "Yes"}
                title={option}
              >
                {option}
              </Option>
            ))}
          </Select>
          {typeof managerData?.value === "boolean" && isRockCreek && (
            <div className="rating-txt">
              Manager Entry: {managerData.value ? "Yes." : "No."}
              <div className="d-flex flex-wrap">
                <FieldStamp field={managerData} />
                <button
                  onClick={() => handleChange(managerData.value)}
                  type="button"
                  className="in-blue-500"
                >
                  <span> Move to draft </span>
                </button>
              </div>
            </div>
          )}
        </>
      </NotApplicableWrapper>
    </>
  );
}
