import _ from "lodash";

export class Params {
  chart: any;
  x_: any;
  y_: any;
  z_: any;
  color_: any;

  constructor(chart: any, defaults: any) {
    this.chart = chart;

    // All Params Should be Present by Now
    // Cant Set Directly - Axes Not Initialized Yet
    this.x_ = defaults.x;
    this.y_ = defaults.y;
    this.z_ = defaults.z;
    this.color_ = defaults.color;

    // _.each(this.chart.required_axes, function(dim){
    //     if(!this[dim]) throw new Error('All Parameters Must be Specified to Initialize')
    // })
  }

  static get params() {
    return ["x", "y", "z", "color"] as const;
  }

  // Resets Multiple Parameters So As Not to Rerender Axes
  // This is Used When Chart Type is Changing - Params Are Reset and Axes Redrawn At Once
  reset(params: any) {
    let self = this;
    Object.keys(params).forEach(function (dim: any) {
      if (!self[(dim + "_") as keyof typeof self])
        throw new Error("Invalid Dimension " + dim);
      self[(dim + "_") as keyof typeof self] = params[dim];
    });
  }

  // Putting Setters Here Causes Updating Chart Params to Immediately Reflect in Axes
  get x() {
    return this.x_;
  }

  set x(value) {
    this.x_ = value;
  }

  get y() {
    return this.y_;
  }

  set y(value) {
    this.y_ = value;
  }

  get z() {
    return this.z_;
  }

  set z(value) {
    this.z_ = value;
  }

  get color() {
    return this.color_;
  }

  set color(value) {
    this.color_ = value;
  }
}
