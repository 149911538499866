import Utilities from "../utilities/utilities";

import Notifications from "../utilities/notifications";
import _ from "lodash";

export class DataPoint {
  public index: any;
  public row: any;
  public model: any;
  public valid: any;
  public errors: any;
  public warnings: any;
  public Name: any;
  public Date: any;
  public NameID: any;
  public Group: any;

  // To Do: Allow parsing parameter as 'strict' to either throw errors or create
  // errors to be referenced after.
  constructor(row: any, index: any, model: any) {
    this.index = index; // Row Index
    this.row = row;
    this.model = model;

    this.valid = true;
    this.errors = new Notifications.Errors(this);
    this.warnings = new Notifications.Warnings(this);

    this.Name = row.Name;
    if (!this.Name) this.errors.missing("Name");

    this.Date = row.Date;
    if (!this.Date) this.errors.missing("Date");

    this.NameID = null; // Need to Assign Externally
    this.Group = null; // Groups Identified in Settings

    let self = this;

    _.each(this.model.groups, function (group) {
      if (group.contains(self)) {
        if (self.Group != null) {
          throw new Error("Points Cannot Belong to Multiple Groups");
        } else self.Group = group;
      }
    });

    // Categorical Parameters - Cannot Defalut Missing Values for Now - Will Cause Point to be Invalid
    _.each(model.categoric, function (param: keyof typeof self) {
      self[param] = Utilities.Format.is_valid_string(row[param]) as any;
      if (!self[param]) {
        self.errors.invalid(param, row[param], self);
      }
    });

    // Numerical Parameters - Set Missing/Invalid Values to 0.0
    _.each(model.numeric, function (param: keyof typeof self) {
      self[param] = Utilities.Format.is_valid_float(row[param]);
    });
  }
}
