import { Bar_Chart } from "./script/bar_chart";
import { Bubble_Chart } from "./script/bubble_chart";
import {
  IBarChartSettings,
  IBubbleChartSettings,
  ITransitionChartSettings,
} from "./script/settings/settings";
import { Transition_Chart } from "./script/transition_chart";
import "./static/charting.css";
import "./static/context-menu.css";
import "./static/controls.css";
import "./static/elements.css";
import "./static/fundlist.css";
import "./static/timeline.css";

function validate(containerId: string, data: any[]) {
  if (!containerId) {
    throw new Error("Error: Must Specify Valid Container for Chart");
  }
  if (!Array.isArray(data) || !data.length) {
    throw new Error("Error: Must Provide Non Empty Data Array");
  }
}

export class TransitionVisualization {
  readonly chart: Transition_Chart;

  constructor(
    containerId: string,
    data: any[],
    settings: ITransitionChartSettings
  ) {
    validate(containerId, data);
    this.chart = new Transition_Chart(data, containerId, settings);
    this.chart.afterInitialize();
  }
}

export class BubbleVisualization {
  readonly chart: Bubble_Chart;

  constructor(
    containerId: string,
    data: any[],
    settings: IBubbleChartSettings
  ) {
    validate(containerId, data);
    this.chart = new Bubble_Chart(data, containerId, settings);
    this.chart.afterInitialize();
  }
}

export class BarVisualization {
  readonly chart: Bar_Chart;

  constructor(containerId: string, data: any[], settings: IBarChartSettings) {
    validate(containerId, data);
    this.chart = new Bar_Chart(data, containerId, settings);
    this.chart.afterInitialize();
  }
}
