import * as d3 from "d3";
import _ from "lodash";
import { Constants } from "../constants";
import type { DropdownControl } from "./controls";

export interface IDropdownScroller {
  maxItems: number;
  startIndex_: number;
  get endIndex(): number;
  get visible(): unknown[];
  get startIndex(): number;
  set startIndex(value: number);
  dragScrollBar(event: DragEvent): void;
  removeScroll(): void;
  drawScroll(
    selector: d3.Selection<SVGGElement, any, HTMLElement, any>,
    range: [number, number],
    position: { x: number; y: number }
  ): void;
  domain?: number[];
  overlay?: d3.Selection<SVGRectElement, any, HTMLElement, any>;
  slider?: d3.Selection<SVGRectElement, any, HTMLElement, any>;
  scrollScale?: d3.ScaleLinear<number, number>;
  scrollScaleIdentifier?: d3.ScaleQuantize<number>;
}

export type DropdownScroller<T> = T & IDropdownScroller;

export function intoDropdownScroller<T>(t: T) {
  return t as DropdownScroller<T>;
}

export function DropdownScroller<T>(
  this: DropdownControl<T> & DropdownScroller<T>,
  maxItems: number
) {
  this.maxItems = maxItems; // Constant - To Do: Make Dependent on the Max Height Specification
  if (!this.maxItems) this.maxItems = 10;

  this.startIndex_ = 0;

  Object.defineProperty(this, "endIndex", {
    configurable: true,
    get: function endIndex() {
      return this.startIndex + this.maxItems;
    },
  });

  Object.defineProperty(this, "visible", {
    configurable: true,
    get: function visible() {
      if (!this.data.children) return [];
      else return this.data.children.slice(this.startIndex, this.endIndex);
    },
  });

  Object.defineProperty(this, "startIndex", {
    configurable: true,
    get: function startIndex() {
      return this.startIndex_;
    },
    set: function startIndex(value) {
      this.startIndex_ = value;
    },
  });

  // Called When Scrollbar Scrolled
  this.dragScrollBar = function (event: DragEvent) {
    const scrollAreaElement = document.getElementById(this.itemsScrollArea);
    if (!scrollAreaElement) {
      console.error("Scroll area element not found!");
      return;
    }

    // Use d3.pointer to get the mouse position relative to the scroll area
    const pointer = d3.pointer(event, scrollAreaElement);
    const mouseY = pointer[1];

    let posY = this.scrollScale!.invert(mouseY);
    this.slider!.attr("y", posY);

    let index = this.scrollScaleIdentifier!(mouseY);
    let point = this.data.children[index];

    if (!point) throw new Error("Scroll Error");
    this.startIndex = index; // Rerenders in Setter
    this.drawChildren();
  };

  this.removeScroll = function () {
    if (this.overlay) this.overlay.remove();
    if (this.slider) this.slider.remove();
  };

  // Draw Scroll Bar After Initial Render so Dimensions Set
  this.drawScroll = function (
    selector: d3.Selection<SVGGElement, any, HTMLElement, any>,
    range: [number, number],
    position: { x: number; y: number }
  ) {
    let self = this;

    // Cut Off Excess At End So Scroll Domain Correct
    let end = Math.max(this.data.children.length - this.maxItems + 1, 0);
    this.domain = [];
    for (let i = 0; i < end; i++) {
      this.domain.push(i);
    }

    if (this.domain.length > 0) {
      range[1] = range[1] - Constants.Scroll.slider.height - 1.5;
      range[0] = range[0] - 0.5;
      this.overlay = selector
        .append("rect")
        .attr("class", "dropdown-scroll-overlay")
        .attr("rx", 0.0)
        .attr("ry", 0.0)
        .attr("x", position.x)
        .attr("y", range[0])
        .attr("width", Constants.Scroll.width)
        .attr("height", range[1] - range[0] + Constants.Scroll.slider.height);

      this.slider = selector
        .append("rect")
        .attr("class", "scroll-slider")
        .attr("height", Constants.Scroll.slider.height)
        .attr("width", Constants.Scroll.width)
        .attr("rx", 0.0)
        .attr("ry", 0.0)
        .attr("y", range[0] + 0.5)
        .attr("x", function () {
          return position.x;
        })
        .call(d3.drag().on("drag", _.bind(self.dragScrollBar, self)) as any);

      // Scroll Scale Maps Drag Point to Clamped Point in Range, Identifier Maps Drag Point to Specific Name
      let updatedRange = range.slice();
      updatedRange[0] = updatedRange[0] + 1.5;
      updatedRange[1] = updatedRange[1] - 0.5;
      this.scrollScale = d3
        .scaleLinear()
        .domain(range)
        .range(range)
        .clamp(true);
      this.scrollScaleIdentifier = d3
        .scaleQuantize()
        .domain(range)
        .range(this.domain);
    }
  };

  return this;
}
