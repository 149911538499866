import _ from "lodash";

class Assertions {
  function(name: string, value: any): asserts value is null | Function {
    if (value === null) {
      return;
    }
    if (!(value instanceof Function)) {
      throw new Error(name + " Must be a Valid Function");
    }
  }

  string_if_defined(name: string, value: any): asserts value is null | string {
    if (value === null) {
      return;
    }
    this.string(name, value);
  }

  defined<T>(name: string, value: T): asserts value is NonNullable<T> {
    if (value === undefined || value === null) {
      throw new Error(name + " Must be Defined");
    }
    return;
  }

  array(name: string, value?: any, options?: any): asserts value is any[] {
    if (Array.isArray(value)) {
      for (const val of value) {
        if (options) {
          if (!Array.isArray(options)) {
            throw new Error("Options Must Be an Array");
          }
          if (!options.includes(val)) {
            throw new Error("Invalid Specification " + val + " for " + name);
          }
        }
      }
    } else {
      throw new Error(name + " Must be An Array");
    }
  }

  among(name: string, value: any, options?: any) {
    if (!Array.isArray(options)) {
      throw new Error("Options Must Be an Array");
    }
    if (!options.includes(value)) {
      throw new Error(name + " Must be One of " + options.join(","));
    }
  }

  string(name: string, value: any): asserts value is string | String {
    if (typeof value === "string" || value instanceof String) {
      return;
    }
    throw new Error(name + " Must be a String");
  }

  numeric(name: string, value: any) {
    value = parseFloat(value);
    if (isNaN(value)) {
      throw new Error(name + " Must be Numeric");
    }
  }

  bool(name: string, value: any): asserts value is boolean {
    if (value !== true && value !== false) {
      throw new Error(name + " Must be Boolean");
    }
  }
}

export const ASSERTIONS: Assertions = new Assertions();
