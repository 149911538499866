import { useQuery } from "@apollo/client";
import { Spin } from "antd";
import * as d3 from "d3";
import React, { useEffect, useMemo } from "react";
import { GET_RISK_VISUALIZATIONS_DATA } from "./graphql";
import { TransitionVisualization } from "./port/src/index";

function RiskVisualizations({
  portfolioId,
  heightOffset,
}: {
  portfolioId: number;
  heightOffset: number;
}): JSX.Element {
  const [chart, setChart] = React.useState<TransitionVisualization | null>(
    null
  );
  const [error, setError] = React.useState<Error | null>(null);

  const divId = useMemo(() => Math.random().toString(36).substring(2), []);
  const visualizationRef = React.useRef<HTMLDivElement>(null);

  const {
    data,
    loading,
    error: queryError,
  } = useQuery(GET_RISK_VISUALIZATIONS_DATA, {
    variables: { portfolioId },
  });

  useEffect(() => {
    if (queryError) {
      setError(queryError);
    }
  }, [queryError]);

  useEffect(() => {
    const div = visualizationRef.current;
    if (!div) return;

    const draw = () => {
      // compute size
      const width = div.clientWidth;
      const height = window.innerHeight - heightOffset;

      d3.select(div).selectAll("*").remove();

      setError(null);
      if (data?.riskVisualizationsData.json.length) {
        try {
          const chart = new TransitionVisualization(
            divId,
            data.riskVisualizationsData.json,
            {
              fundlist: { width: 250 },
              chart: {
                width,
                height,
                params: {
                  z: "same",
                  color: "Strategy",
                },
              },
            }
          );
          setChart(chart);
        } catch (e: any) {
          setError(e instanceof Error ? e : new Error(e));
        }
      }
    };

    draw();

    window.addEventListener("resize", draw);
    const observer = new ResizeObserver(draw);
    observer.observe(div);

    return () => {
      setError(null);
      if (visualizationRef.current) {
        d3.select(visualizationRef.current).selectAll("*").remove();
      }
      window.removeEventListener("resize", draw);
      observer.disconnect();
    };
  }, [data]);

  return (
    <div>
      {loading && <Spin />}
      <div
        id={divId}
        ref={visualizationRef}
        data-cy={"visualizations-chart-container"}
      />
      {data?.riskVisualizationsData.json.length === 0 && (
        <div>No data available</div>
      )}
      {error?.message &&
        JSON.stringify({
          message: error.message,
          stacktrace: error.stack,
        })}
    </div>
  );
}

export default RiskVisualizations;
