import { ParentSettings } from "./parent";

export interface IControlSettings {
  enabled?: boolean;
}

abstract class ControlSettingsParent extends ParentSettings<IControlSettings> {
  public enabled = true;

  constructor(settings?: IControlSettings) {
    super(settings, [], "Controls", {
      enabled: "bool",
    });
  }
}

export class BarControlSettings extends ControlSettingsParent {
  params: ("x" | "y" | "color")[];

  constructor(settings?: IControlSettings) {
    super(settings);

    this.params = ["x", "y", "color"];
    this.validation["params"] = ["array", ["x", "y", "color"]];
  }
}

export class BubbleControlSettings extends ControlSettingsParent {
  params: ("x" | "y" | "z" | "color")[];

  constructor(settings?: IControlSettings) {
    super(settings);

    this.params = ["x", "y", "z", "color"];
    this.validation["params"] = ["array", ["x", "y", "z", "color"]];
  }
}

// TO DO: Create Validation for Transition Control Params
export class TransitionControlSettings extends ControlSettingsParent {
  params: {
    bubble: ("x" | "y" | "z" | "color")[];
    bar: ("x" | "y" | "color")[];
  };

  constructor(settings?: IControlSettings) {
    super(settings);

    this.params = {
      bubble: ["x", "y", "z", "color"],
      bar: ["x", "y", "color"],
    };
  }
}
